import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Button,
    Form,
    TextInput,
    Header,
    Wrapper,
} from 'library';

class PDFList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forms: [],
            name: '',
        };

        this.load_pdfs = this.load_pdfs.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET', '/api/pdf_printer/pdfform/', {}, this.load_pdfs);
    }

    load_pdfs(result) {
        const forms = [];
        for (const index in result) {
            const form = result[index].pdfform;
            forms.push(form);
        }

        this.setState({ forms, loaded: true });
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    render() {
        const forms = [];
        for (const index in this.state.forms) {
            const form = this.state.forms[index];
            if (
                this.state.name == '' ||
                form.name.toLowerCase().indexOf(this.state.name.toLowerCase()) >
                -1
            ) {
                forms.push(
                    <tr>
                        <td>{form.name}</td>
                        <td>
                            <a href={`/pdf/editform/${form.id}/`}>Edit</a>
                        </td>
                        <td>{form.id}</td>
                    </tr>
                );
            }
        }

        const form_filter = (
            <Form
                components={[TextInput]}
                componentProps={[{ label: 'Name', name: 'name' }]}
                defaults={this.state}
                setGlobalState={this.setGlobalState}
                autoSetGlobalState
                globalStateName="form"
            />
        );

        const content = (
            <div className="container-fluid">
                <Header size={2} text="PDFs" />
                <br />
                {form_filter}

                <Header size={3} text="Forms" />
                <Button
                    type="success"
                    text="Add New Form"
                    href="/pdf/editform/"
                    style={{ marginBottom: '20px' }}
                />
                <br />

                <table className="table">
                    <tbody>
                        <tr>
                            <th>Company</th>
                            <th>Name</th>
                            <th>Edit</th>
                        </tr>
                        {forms}
                    </tbody>
                </table>
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default PDFList;
