import React, { Component as ComponentBase } from 'react';
import ajaxWrapper from 'base/ajax.js';

import CleverLogin from 'projectLibrary/clever_login.js';
import ClasslinkLogin from 'pages/classlink/login.js';

class LogInForm extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = { email: '', error: '' };

        this.handleChange = this.handleChange.bind(this);

        this.preflight_check = this.preflight_check.bind(this);
        this.preflight_check_callback =
            this.preflight_check_callback.bind(this);

        this.formSubmit = this.formSubmit.bind(this);
        this.formSubmitCallback = this.formSubmitCallback.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.check_district_auth = this.check_district_auth.bind(this);
    }

    componentDidMount() {
        if ('error' in this.props.params) {
            this.setState({ error: decodeURI(this.props.params.error) });
        }
    }

    handleChange = e => {
        const name = e.target.getAttribute('name');
        const newState = {};
        newState[name] = e.target.value;
        this.setState(newState);
    };

    preflight_check() {
        ajaxWrapper(
            'POST',
            '/users/userCheck/',
            { email: this.state.email },
            this.preflight_check_callback
        );
    }

    preflight_check_callback(value) {
        if ('error' in value) {
            this.setState({ error: value.error });
        } else {
            this.formSubmit();
        }
    }

    formSubmit() {
        const data = this.state;
        data.email = data.email.toLowerCase();

        if (data.email.indexOf('sandi.net') > -1) {
            ajaxWrapper(
                'POST',
                '/users/check_district_auth/',
                { email: data.email },
                this.check_district_auth
            );
        } else {
            ajaxWrapper(
                'POST',
                this.props.submitUrl,
                data,
                this.formSubmitCallback
            );
        }
    }

    check_district_auth(result) {
        if (result.error == null) {
            const data = this.state;
            data.email = data.email.toLowerCase();
            ajaxWrapper(
                'POST',
                this.props.submitUrl,
                data,
                this.formSubmitCallback
            );
        } else {
            this.setState({ error: result.error });
        }
    }

    formSubmitCallback(value) {
        if ('error' in value) {
            if (value.error === 'Invalid Credentials') {
                this.setState({
                    error: (
                        <p>
                            Wrong Email or Password. If this is your first time
                            logging in, you may need to{' '}
                            <a href="/passwordResetRequest/">
                                reset your password first.
                            </a>
                        </p>
                    ),
                });
            } else {
                this.setState({ error: value.error });
            }
        } else {
            localStorage.setItem('token', value.access);
            localStorage.setItem('refresh_token', value.refresh);
            localStorage.setItem('token_time', new Date());

            if (localStorage.getItem('redirect'))
              {
                const redirect = localStorage.getItem('redirect');
                localStorage.removeItem('redirect');
                window.location.href = redirect
            } else {
                window.location.href = this.props.redirectUrl;
            }
        }
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.preflight_check();
        }
    }

    // Not in District User Table
    //
    // Or Contact

    // In District User Table
    // Not In User Table? Or Not Connected To District User?
    // Fix it

    // In User Table and Connected
    // Login or Forgot Password Flow

    render() {
        let classCss = 'form';
        if (this.props.row === true) {
            classCss = 'form-row';
        }

        const components = [];
        Object.keys(this.props.components).forEach(index => {
            const Component = this.props.components[index];
            const props = this.props.componentProps[index];
            components.push(
                <Component
                    {...props}
                    handlechange={this.handleChange}
                    value={this.state[props.name]}
                />
            );
        });

        const buttons = [];
        const submitButton = (
            <button
                style={{ width: '100%' }}
                className="btn btn-success"
                onClick={this.preflight_check}
                type="button"
            >
                Log In
            </button>
        );
        buttons.push(submitButton);

        let error = <div />;
        if (this.state.error != '') {
            error = (
                <div
                    className="alert alert-danger"
                    style={{ wordWrap: 'break-word' }}
                    role="alert"
                >
                    {this.state.error}
                </div>
            );
        }

        // need to add in formsubmit, delete, and handle change functions to components.
        // <small className="form-text">Not a user yet? <a href="/signUp/">Sign Up Here</a></small>

        return (
            <div className={classCss} onKeyDown={this._handleKeyDown}>
                {error}
                {components}
                {buttons}

                <div style={{ padding: '10px', textAlign: 'center' }}>
                    - or -
                </div>
                <div style={{ padding: '0px 0px 20px 0px' }}>
                    <CleverLogin />
                    <ClasslinkLogin />
                </div>

                <small className="form-text" style={{ marginTop: '10px' }}>
                    Forgot your password?{' '}
                    <a href="/passwordResetRequest/">Reset Password</a>
                </small>

                <small className="form-text" style={{ marginTop: '10px' }}>
                    By logging in and using this site, you agree to our{' '}
                    <a href='/terms/' target='_blank'>Terms of Service</a>
                </small>

                <br />
                {error}
            </div>
        );
    }
}

export default LogInForm;
