import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    FormWithChildren,
    TextInput,
    Select,
    DateTimePicker,
    NumberInput,
    Alert,
    Button,
    Wrapper,
} from 'library';

const ERROR_MAP = {
    remaining_responses: 'Remaining unscored responses',
    no_score: '0% of students got it correct',
    no_components: 'No components have been assigned to responses',
    low_score: 'Less than 10% got it correct',
    automated_mcl_scores_only: 'Not a single person got logic',
    low_mcl_score: 'Number of mcl combinations is less than 7',
    only_generic_WOT: 'Only generic ways assigned',
    no_full_credit: 'No student receieved full credit',
    unassigned_assessments: 'Some Assessments Are Still Un-assigned.'
};

import DownloadModal from 'pages/reports/results_explorer/components/download_modal';

class AssessmentSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: '',
            loading: false,
            can_complete: false,
            show_download: false,
        };

        this.check_assessment = this.check_assessment.bind(this);
        this.check_assessment_callback =
            this.check_assessment_callback.bind(this);
        this.complete_assessment = this.complete_assessment.bind(this);
        this.complete_assessment_callback =
            this.complete_assessment_callback.bind(this);
    }

    check_assessment() {
        this.setState(
            {
                loading: true,
            },
            () =>
                ajaxWrapper(
                    'GET',
                    `/check_assessment_completeness/${this.props.assessment.id}/`,
                    {},
                    this.check_assessment_callback
                )
        );
    }

    check_assessment_callback(value) {
        if (value.length == 0) {
            this.setState({
                can_complete: true,
                loading: false,
            });
        } else {
            this.setState({
                errors: value,
                loading: false,
            });
        }
    }

    complete_assessment() {
        const data = {
            completed: true,
        };

        ajaxWrapper(
            'POST',
            `/api/home/assessment/${this.props.assessment.id}/`,
            data,
            this.complete_assessment_callback
        );
    }

    complete_assessment_callback() {
        window.location.reload();
    }

    render() {
        let submitUrl = '/api/home/assessment/';
        let deleteUrl = null;
        if (this.props.assessment.id) {
            submitUrl += `${this.props.assessment.id}/`;
            deleteUrl = `${submitUrl}delete/`;
        }
        if (this.props.isDuplicated) {
            submitUrl = '/duplicate_assessment/';
        }
        console.log('object', submitUrl);
        
        var download = <Button
                onClick={() => this.setState({ show_download: true })}
                text="Download Data"
                type="primary"
            />
            if (this.state.show_download) {
                download = <DownloadModal
                    key={this.props.assessment.id + '_download'}
                    url={`/download_assessment_data/${this.props.assessment.id}/`}
                    query={{}}
                    onHide={() => this.setState({ show_download: false })}
                    filename = {'AssessmentData_' + this.props.assessment.name + '.csv'}
                />
            }

        let admin_tasks = null;
        if (
            window.cmState.user.role == 'Super Admin' &&
            !this.props.isDuplicated
        ) {
            admin_tasks = (
                <Alert type="warning">
                    <h5>Admin Tasks:</h5>
                    <Button
                        style={{ marginBottom: '15px' }}
                        href={`/upload_assessment_responses/${this.props.assessment.id}/`}
                        text="Upload Responses"
                        type="success"
                    />
                    {download}
                    <br />
                    <Button
                        href={`/score_assessment/${this.props.assessment.id}/`}
                        text="Score the Assessment"
                        type="info"
                    />
                    <Button
                        text="Duplicate"
                        type="info"
                        onClick={() => {
                            this.props.setDuplica(null);
                        }}
                    />
                    <Button
                        text="Create Reports"
                        type="primary"
                        href={`/create_reports/`}
                    />
                </Alert>
            );
        }

        const defaults = this.props.assessment;
        defaults.district = this.props.assessment.district_id;
        defaults.school_term = this.props.assessment.school_term_id;

        let complete_assessment = (
            <Alert type="success">Assessment Completed!</Alert>
        );
        if (this.state.errors) {
            const errors = [];
            for (const item of this.state.errors) {
                for (const key in item) {
                    let link = '';
                    if (
                        [
                            'remaining_responses',
                            'no_score',
                            'low_score',
                        ].indexOf(key) > -1
                    ) {
                        // non_explanation_questions
                        link = `/student_response_grouping/${item[key]}/`;
                    } else if (key == 'unassigned_assessments') {
                        link = `/anonymous_assessments/${item[key]}/`
                    }
                    else {
                        // explanation_questions
                        link = `/item_answers/${item[key]}/`;
                    }

                    let error_text = key;
                    if (error_text in ERROR_MAP) {
                        error_text = ERROR_MAP[error_text];
                    }

                    errors.push(
                        <tr>
                            <td>{error_text}</td>
                            <td>
                                <Button
                                    key={item[key]}
                                    target="_blank"
                                    text="Go"
                                    href={link}
                                    type="primary"
                                />
                            </td>
                            <td>{item[key]}</td>
                        </tr>
                    );
                }
            }

            complete_assessment = (
                <Alert type="danger">
                    <div>
                        <h4>
                            Assessment is incomplete for the following reasons
                        </h4>
                    </div>
                    <div>
                        <table className="table">
                            <tr>
                                <td>Reason</td>
                                <td>Link</td>
                                <td>ID</td>
                            </tr>
                            {errors}
                        </table>
                    </div>
                    <div>
                        <Button
                            type="success"
                            text="Complete Assessment"
                            onClick={this.complete_assessment}
                        />
                    </div>
                </Alert>
            );
        } else if (!this.props.assessment.completed) {
            if (this.state.can_complete) {
                complete_assessment = (
                    <Alert type="info">
                        <div>No Issues Detected</div>
                        <Button
                            type="success"
                            text="Complete Assessment"
                            onClick={this.complete_assessment}
                        />
                    </Alert>
                );
            } else if (!this.props.isDuplicated) {
                complete_assessment = (
                    <Alert type="info">
                        <Button
                            type="primary"
                            text="Check If Assessment Is Complete"
                            onClick={this.check_assessment}
                        />
                    </Alert>
                );
            } else {
                complete_assessment = <></>;
            }
        }

        

        return (
            <div style={{ padding: '10px 0px' }}>
                <FormWithChildren
                    className="row"
                    submitUrl={submitUrl}
                    deleteUrl={deleteUrl}
                    redirectUrl="/manage_assessments/"
                    defaults={this.props.assessment}
                    key={this.props.form_key}

                    protect_form={this.props.protect_form}
                    protect_form_text={this.props.protect_form_text}
                >
                    <TextInput className="col-12" name="name" label="Name" />

                    <Select
                        className="col-6"
                        optionsUrl="/api/sis/district/"
                        optionsUrlMap={{
                            text: '{district.name}',
                            value: '{district.id}',
                        }}
                        name="district"
                        label="District"
                    />
                    <Select
                        className="col-6"
                        optionsUrl="/api/sis/schoolterm/"
                        optionsUrlMap={{
                            text: '{schoolterm.name}',
                            value: '{schoolterm.id}',
                        }}
                        name="school_term"
                        label="School Term"
                    />

                    <TextInput
                        className="col-6"
                        name="grade_levels"
                        label="Grades (Comma Seperated. Ex. 9,10,11,12)"
                    />
                    <Select
                        className="col-6"
                        boolean
                        name="calculator_allowed"
                        label="Calculator Allowed"
                    />

                    <DateTimePicker
                        className="col-4"
                        required
                        name="date_window_opens"
                        label="Date To Be Given"
                        display_time={false}
                    />
                    <DateTimePicker
                        className="col-4"
                        required
                        name="date_analysis_started"
                        label="Date To Start Analysis"
                        display_time={false}
                    />
                    <DateTimePicker
                        className="col-4"
                        required
                        name="date_analysis_finished"
                        label="Date To Finish Analysis"
                        display_time={false}
                    />
                    <DateTimePicker
                        className="col-4"
                        required
                        name="date_window_closes"
                        label="Date To Close Assessment"
                        display_time={false}
                    />

                    <NumberInput
                        className="col-6"
                        name="predicted_number_of_students"
                        label="Predicted Number of Responses On Assessment"
                    />
                    <NumberInput
                        className="col-6"
                        name="analysts_needed"
                        label="Analysts Needed"
                        blank_value={0}
                    />

                    <Select
                        className="col-6"
                        options={[
                            { text: 'Publish', value: 'true' },
                            { text: 'Hide', value: 'false' },
                        ]}
                        name="published"
                        label="Publish To District?"
                    />
                    <Select
                        className="col-6"
                        options={[
                            { text: 'Not Billed', value: 'Not Billed' },
                            {
                                text: 'Billed Awaiting Payment',
                                value: 'Billed Awaiting Payment',
                            },
                            { text: 'Paid', value: 'Paid' },
                        ]}
                        name="invoice_status"
                        label="Invoice Status"
                    />
                    <div className="col-12" />
                </FormWithChildren>

                <br />
                <br />

                {admin_tasks}

                <Wrapper
                    loaded={!this.state.loading}
                    style={{ marginTop: '0px' }}
                >
                    {complete_assessment}
                </Wrapper>
            </div>
        );
    }
}

export default AssessmentSettings;
