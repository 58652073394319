import React from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Container, Wrapper, PageBreak, Alert } from 'library';

function make_color(percentage) {
    let color = 'rgb(255, 255, 255, .2)';

    if (percentage) {
        const red = parseInt(255 * (parseFloat(100 - percentage) / 100));
        const green = parseInt(255 * (parseFloat(percentage) / 100));
        color = `rgb(${  red  }, ${  green  }, 0, .2)`;
    }

    return color;
}

class FunctionOverview extends React.Component {
    render() {
        const {component} = this.props;
        const nyckel_function = component.functions[0].nyckelfunction;

        let training_incorrect_percentage = 100;
        if (nyckel_function.training_set_count > 0) {
            training_incorrect_percentage = parseInt(
                (parseFloat(nyckel_function.training_set_incorrect_count) /
                    parseFloat(nyckel_function.training_set_count)) *
                    100
            );
        }
        const training_color = make_color(100 - training_incorrect_percentage);

        let sample_percentage = 0;
        if (nyckel_function.samples_count > 0) {
            sample_percentage = parseInt(
                (parseFloat(nyckel_function.samples_high_confidence_count) /
                    parseFloat(nyckel_function.samples_count)) *
                    100
            );
        }
        const sample_color = make_color(sample_percentage);

        let approved = (
            <Alert type="warning">
                <div>Not approved to score</div>
                <div>
                    {`${nyckel_function.total_unscored_responses  } to be scored`}
                </div>
            </Alert>
        );
        if (nyckel_function.approved_to_assign) {
            approved = (
                <Alert type="success">
                    <div>
                        {`Has scored ${  nyckel_function.total_scored_responses}`}
                    </div>
                    <div>
                        {`${nyckel_function.total_unscored_responses 
                            } will be scored`}
                    </div>
                </Alert>
            );
        }

        return (
            <div>
                <div className="row">
                    <div className="col-3">
                        <div style={{ padding: '10px', borderRadius: '4px' }}>
                            <h6>{component.name}</h6>
                        </div>
                    </div>
                    <div className="col-3">
                        <div
                            style={{
                                background: training_color,
                                padding: '10px',
                                borderRadius: '4px',
                            }}
                        >
                            <div>
                                {`Training: ${ 
                                    nyckel_function.training_set_count}`}
                            </div>
                            <div>
                                {`Incorrect: ${ 
                                    nyckel_function.training_set_incorrect_count}`}
                            </div>
                            <div>{`${training_incorrect_percentage  }%`}</div>
                        </div>
                    </div>

                    <div className="col-3">
                        <div
                            style={{
                                background: sample_color,
                                padding: '10px',
                                borderRadius: '4px',
                            }}
                        >
                            <div>
                                {`Samples: ${  nyckel_function.samples_count}`}
                            </div>
                            <div>
                                {`With high confidence: ${ 
                                    nyckel_function.samples_high_confidence_count}`}
                            </div>
                            <div>{`${sample_percentage  }%`}</div>
                        </div>
                    </div>

                    <div className="col-3">
                        <a
                            style={{ marginBottom: '15px' }}
                            href={
                                `/nyckel_overview/${  nyckel_function.id  }/`
                            }
                            className="btn btn-primary"
                        >
                            Explore
                        </a>
                        {approved}
                    </div>
                </div>

                <PageBreak style={{ margin: '10px' }} />
            </div>
        );
    }
}

export default class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: [],
        };

        this.load_overview = this.load_overview.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET', '/nyckel/overview/', {}, this.load_overview);
    }

    load_overview(value) {
        this.setState({
            loaded: true,
            data: value,
        });
    }

    render() {
        const questions = [];
        for (const question of this.state.data) {
            const approaches = [];

            const question_total = 0;
            const question_unscored = 0;

            for (const approach of question.approaches) {
                const components = [];

                for (const component of approach.components) {
                    components.push(<FunctionOverview component={component} />);
                }

                approaches.push(
                    <div>
                        <h5>{approach.name}</h5>
                        <div>{components}</div>
                    </div>
                );
            }

            questions.push(
                <div className="simple-card">
                    <h4>{question.name}</h4>
                    <PageBreak style={{ margin: '10px' }} />
                    {approaches}
                </div>
            );
        }

        return (
            <Wrapper loaded={this.state.loaded} style={{ margin: '0px' }}>
                <div className="sad" style={{ padding: '30px 0px 50px 0px' }}>
                    <Container min_height>{questions}</Container>
                </div>
            </Wrapper>
        );
    }
}
