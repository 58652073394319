import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Form,
    TextInput,
    Alert,
    Wrapper,
} from 'library';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            message: null,
        };

        this.logIn = this.logIn.bind(this);
        this.email_new_user_callback = this.email_new_user_callback.bind(this);
    }

    logIn(value) {
        if (value.error) {
            this.setState({
                error: value.error,
                message: null,
            });
        } else {
            const { user } = value[0];

            ajaxWrapper(
                'POST',
                '/api/email/',
                {
                    to_email: user.email,
                    from_email: 'support@mathanex.com',
                    subject: 'MathAnex Signup',
                    text:
                        `You have been registered on MathAnex.  To login please first set your password at http://mathanex.com/passwordReset/${user.password_reset_code
                        }/`,
                },
                this.email_new_user_callback
            );
        }
    }

    email_new_user_callback() {
        this.setState({
            message:
                'User Created Successfully.  An email has been sent instructing them how to login.',
            error: null,
        });
    }

    render() {
        const Components = [TextInput, TextInput, TextInput];
        const first_name_props = {
            value: '',
            name: 'first_name',
            label: 'First Name:',
            placeholder: 'First Name',
        };
        const last_name_props = {
            value: '',
            name: 'last_name',
            label: 'Last Name:',
            placeholder: 'Last Name',
        };
        const email_props = {
            value: '',
            name: 'email',
            label: 'Email:',
            placeholder: 'math@mathanex.com',
        };

        const ComponentProps = [first_name_props, last_name_props, email_props];
        const defaults = {
            first_name: '',
            last_name: '',
            email: '',
            type: 'User',
            role: 'Analyst',
            forced_password_reset: true,
        };

        const submitUrl = '/users/signup/';

        let error = null;
        const { error: errorState, message: messageState } = this.state;
        if (errorState) {
            error = <Alert type="danger" text={errorState} />;
        }
        let message = null;
        if (messageState) {
            message = <Alert type="success" text={messageState} />;
        }

        const content = (
            <div className="container">
                <div className="row">
                    <div className="col-md-4" />
                    <div className="col-md-4">
                        <h2>Sign Up</h2>
                        <Form
                            components={Components}
                            redirect={this.logIn}
                            componentProps={ComponentProps}
                            submitUrl={submitUrl}
                            defaults={defaults}
                        />
                        {error}
                        {message}
                    </div>
                    <div className="col-md-4" />
                </div>
            </div>
        );

        return <Wrapper loaded content={content} />;
    }
}
export default SignUp;
