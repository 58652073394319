import React, { Component as ComponentBase } from 'react';
import resolveVariables from 'base/resolver.js';
import ajaxWrapper from 'base/ajax.js';
import { Alert, Button } from 'library';
// Example
// var answerProps = {'name':'response', 'value':''}
// var defaults = {'response':'', 'question':this.props.question_id, 'sid':this.props.user_id}
// var submitUrl = '/api/home/answer/';
// var redirectUrl = '/referenceGuide/' + this.props.question_id + '/';
// <Form components={[TextArea]} first={true} componentProps={[answerProps]} submitUrl={submitUrl} defaults={defaults} redirectUrl={redirectUrl}/>

class Form extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = this.props.defaults;
        this.state.required = '';

        this.handleChange = this.handleChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.formDelete = this.formDelete.bind(this);
        this.formSubmitCallback = this.formSubmitCallback.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.setFormState = this.setFormState.bind(this);
        this.refreshDataCallback = this.refreshDataCallback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        if (this.props.dataUrl) {
            this.refreshData();
        }
    }

    setGlobalState(state) {
        console.log('New Global State', state);
        if (this.props.autoSetGlobalState == true) {
            this.props.setGlobalState(this.props.globalStateName, state);
        }
    }

    handleChange = e => {
        const name = e.target.getAttribute('name');
        const newState = {};
        newState[name] = e.target.value;

        const newCompleteState = this.state;
        newCompleteState[name] = e.target.value;
        this.setState(newState, this.setGlobalState(newCompleteState));
    };

    setFormState(state) {
        const newState = this.state;
        Object.keys(state).forEach(index => {
            newState[index] = state[index];
        });
        this.setState(state, this.setGlobalState(newState));
    }

    refreshData() {
        if (this.props.dataUrl) {
            ajaxWrapper(
                'GET',
                this.props.dataUrl,
                {},
                this.refreshDataCallback
            );
        }
    }

    formSubmit() {
        const data = this.state;

        let failed = false;
        let required = '';
        Object.keys(this.props.componentProps).forEach(index => {
            const prop = this.props.componentProps[index];
            if (prop.required == true) {
                if (this.state[prop.name] == '') {
                    required += `The field ${prop.label} must be filled out to submit the form. `;
                    failed = true;
                }
            }
        });
        if (failed == true) {
            this.setState({ required });
        } else {
            Object.keys(data).forEach(item => {
                if (item.endsWith('[]')) {
                    data[item] = JSON.stringify(data[item]);
                }
            });

            if (this.props.submit) {
                this.props.submit(data);
            } else {
                ajaxWrapper(
                    'POST',
                    this.props.submitUrl,
                    data,
                    this.formSubmitCallback
                );
            }
        }
    }

    refreshDataCallback(value) {
        let newValue = value;
        if (this.props.first == true) {
            if (this.props.objectName) {
                newValue = value[0][this.props.objectName];
            } else {
                [newValue] = value;
            }
        }
        if (this.props.dataMapping) {
            if (value.length != undefined) {
                newValue = { 'photos[]': [] };
                Object.keys(value).forEach(index => {
                    const tempValue = resolveVariables(
                        this.props.dataMapping,
                        value[index]
                    );
                    newValue['photos[]'].push(tempValue['photos[]']);
                });
            } else {
                newValue = resolveVariables(this.props.dataMapping, value);
            }
        }

        this.setState(newValue);
    }

    formSubmitCallback(value) {
        let redirectUrl;
        if (typeof value[0] !== 'undefined') {
            if (this.props.setGlobalState) {
                if (this.props.globalStateName) {
                    this.setState(value[0][this.props.objectName], () =>
                        this.props.setGlobalState(
                            this.props.globalStateName,
                            value[0][this.props.objectName]
                        )
                    );
                } else {
                    this.setState(
                        value[0][this.props.objectName],
                        this.props.setGlobalState('Form', this.state)
                    );
                }
            } else if (value.success == true) {
                // do nothing
            } else if (value[0]) {
                this.setState(value[0][this.props.objectName]);
            }
        }

        if (this.props.deleteRedirectUrl && value.success == true) {
            window.location.href = this.props.deleteRedirectUrl;
        } else if (this.props.redirectUrl) {
            if (this.props.objectName) {
                redirectUrl = resolveVariables(
                    { redirectUrl: this.props.redirectUrl },
                    value[0][this.props.objectName]
                );
            } else {
                redirectUrl = resolveVariables(
                    { redirectUrl: this.props.redirectUrl },
                    value
                );
            }

            window.location.href = redirectUrl.redirectUrl;
        }

        if (this.props.redirect) {
            value.form_state = this.state;
            this.props.redirect(value);
        } else if (this.props.refreshData) {
            this.props.refreshData();
        }
    }

    formDelete() {
        ajaxWrapper(
            'POST',
            this.props.deleteUrl,
            {},
            this.formSubmitCallback.bind(this)
        );
    }

    handleKeyPress = event => {
        if (this.props.submit_on_enter !== false) {
            if (event.key === 'Enter') {
                this.formSubmit();
            }
        }
    };

    render() {
        let layout = '';
        if (typeof this.props.layout !== 'undefined') {
            layout = this.props.layout;
        }

        let classCss = 'form';
        if (this.props.row == true) {
            classCss = 'form-row';
        }
        if (this.props.classCss != undefined) {
            classCss = this.props.classCss;
        }

        const components = [];

        Object.keys(this.props.componentProps).forEach(index => {
            const Component = this.props.components[index];
            const props = this.props.componentProps[index];
            if (index == 0) {
                props.autoFocus = true;
            }

            if (props.names) {
                const values = {};
                Object.keys(props.names).forEach(nameIndex => {
                    const name = props.names[nameIndex];
                    values[name] = this.state[name];
                });
                components.push(
                    <Component
                        {...props}
                        handlechange={this.handleChange}
                        setFormState={this.setFormState}
                        {...values}
                    />
                );
            } else {
                components.push(
                    <Component
                        {...props}
                        handlechange={this.handleChange}
                        setFormState={this.setFormState}
                        value={this.state[props.name]}
                    />
                );
            }
        });

        const buttons = [];
        let float;
        if (this.props.submitUrl || this.props.submit) {
            let classes = 'btn btn-primary';
            if (this.props.submitButtonType) {
                classes = `btn btn-${this.props.submitButtonType}`;
            }
            float = { float: 'left' };
            const submitButton = (
                <Button
                    style={float}
                    className={classes}
                    onClick={this.formSubmit}
                    text="Save"
                />
            );
            buttons.push(submitButton);
        }

        if (this.props.deleteUrl) {
            float = { float: 'right' };
            const deleteButton = (
                <Button
                    style={float}
                    type="danger"
                    onClick={this.formDelete}
                    deleteType
                    text="Delete"
                />
            );
            buttons.push(deleteButton);
        }

        let failed = <div />;
        if (this.state.required != '') {
            failed = <Alert type="danger" text={this.state.required} />;
        }

        // need to add in formsubmit, delete, and handle change functions to components.
        return (
            <div
                className={layout}
                style={this.props.css}
                onKeyPress={this.handleKeyPress}
            >
                {components}
                {failed}
                {buttons}
            </div>
        );
    }
}

export default Form;
