import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { regexCheck } from 'functions';
import {
    Button,
    PageBreak,
    Icon,
    EmptyModal,
    FormWithChildren,
    Select,
    TextInput,
    Alert,
    NumberInput,
    Wrapper,
    TextArea,
    Container,
    Form,
    MarkdownInput,
    Markdown,
} from 'library';

import QuestionImage from 'pages/questions/image.js';

import AssetFilter from 'pages/questions/components/asset_filter.js';

import DownloadModal from 'pages/reports/results_explorer/components/download_modal';

function ExistingAsset(props) {
    const { has_asset } = props;
    if (props.assets_lookup[has_asset.asset_id]) {
        const asset_name = props.assets_lookup[has_asset.asset_id].name;

        let asset_class = 'btn btn-success';
        if (has_asset.heuristic_value === -1) {
            asset_class = 'btn btn-danger';
        }

        return (
            <div
                className={asset_class}
                style={{ margin: '0px 5px 5px 0px' }}
                onClick={() => props.delete_asset(has_asset.id)}
            >
                {asset_name}
            </div>
        );
    } else {
        return null;
    }
}

class CorrectComputations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numbers: '',
            total: 0,
            correct_computations_found: 0,
            groups_found: [],
            group_ids: [],
            found_terms: false,
            examples: '',
        };

        this.check_correct_computations =
            this.check_correct_computations.bind(this);
        this.check_correct_computations_callback =
            this.check_correct_computations_callback.bind(this);

        this.create_correct_computations =
            this.create_correct_computations.bind(this);
        this.create_correct_computations_callback =
            this.create_correct_computations_callback.bind(this);
    }

    check_correct_computations(data) {
        ajaxWrapper(
            'POST',
            '/correct_computations/' + this.props.question_id + '/',
            { numbers: data['numbers'] },
            this.check_correct_computations_callback
        );
    }

    check_correct_computations_callback(data) {
        console.log('Correct Compuations', data);
        data['found_terms'] = true;
        this.setState(data);
    }

    create_correct_computations(data) {
        ajaxWrapper(
            'POST',
            '/save_correct_computations/' + this.props.question_id + '/',
            {
                groups: this.state['group_ids'],
                examples: data['examples'],
            },
            this.create_correct_computations_callback
        );
    }

    create_correct_computations_callback(result) {
        window.location.reload();
    }

    render() {
        var next_steps = null;
        if (this.state.found_terms) {
            var group_examples = [];
            for (var i in this.state.groups_found) {
                group_examples.push(<span>{this.state.groups_found[i]},</span>);
                if (i == 10) {
                    break;
                }
            }
            next_steps = (
                <div>
                    <p>
                        We found {this.state.correct_computations_found} correct
                        computation groups.
                    </p>
                    <p>Ex. {group_examples}</p>
                    <PageBreak />
                    <div style={{ height: '20px' }}></div>
                    <FormWithChildren
                        submit={this.create_correct_computations}
                        submit_text="Create/Assign Correct Computations"
                    >
                        <TextInput
                            name="examples"
                            label="Add 2 Examples"
                            placeholder="4 x 5 = 20 or (1 + 2)/3 = 1"
                        />
                    </FormWithChildren>
                </div>
            );
        }

        return (
            <div style={{ padding: '20px' }}>
                <p>Find Correct Computations</p>
                <FormWithChildren
                    submit={this.check_correct_computations}
                    submit_text="Submit Numbers"
                >
                    <TextInput
                        label="Numbers (Comma seperated, put in a number as many times as it is in the question)"
                        name="numbers"
                    />
                </FormWithChildren>

                {next_steps}
            </div>
        );
    }
}

class QuestionAnswer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            asset_modal: false,
            filter: '',
        };

        this.submit_form = this.submit_form.bind(this);
        this.filter = this.filter.bind(this);

        this.assign_asset = this.assign_asset.bind(this);
        this.delete_asset = this.delete_asset.bind(this);
    }

    submit_form(state, callback) {
        const url = `/api/home/questionanswer/${this.props.answer.id}/`;

        const data = {
            answer: state.answer,
            is_correct: state.is_correct,
            description: state.description,
            who_scored: state.who_scored,
            way_of_thinking: state.way_of_thinking,
        };

        ajaxWrapper('POST', url, data, () => {
            callback(state);
            this.setState({ open: false });
        });
    }

    filter(e) {
        const { value } = e.target;

        this.setState({
            filter: value,
        });
    }

    assign_asset(name, state) {
        const data = {
            answer: this.props.answer.id,
            asset: state.id,
            heuristic_value: state.heuristic_value,
        };

        ajaxWrapper(
            'POST',
            '/api/actionable_assets/QuestionAnswerHasAsset/',
            data,
            () => {
                this.setState({ asset_modal: false });
                this.props.refresh_all(true);
            }
        );
    }

    delete_asset(asset_id) {
        ajaxWrapper(
            'POST',
            `/api/actionable_assets/QuestionAnswerHasAsset/${asset_id}/delete/`,
            {},
            () => this.props.refresh_all(true)
        );
    }

    render() {
        const edit = (
            <Button
                type="info"
                onClick={() => this.setState({ open: true })}
                text="Edit Details"
            />
        );

        const user = this.props.users[this.props.answer.who_scored_id] || {};

        const groups = [];
        const ordered_groups = this.props.answer.response_groups.sort((a, b) =>
            a.studentresponsegroup.answer.localeCompare(
                b.studentresponsegroup.answer
            )
        );

        for (var item of ordered_groups) {
            const group = item.studentresponsegroup;

            if (
                this.state.filter &&
                group.answer
                    .toLowerCase()
                    .indexOf(this.state.filter.toLowerCase()) == -1
            ) {
                continue;
            }

            groups.push(
                <AssignedResponseGroup
                    key={group.id}
                    response_group={group}
                    users={this.props.users}
                    refresh_all={this.props.refresh_all}
                />
            );
        }

        const defaults = {
            answer: this.props.answer.answer,
            is_correct: this.props.answer.is_correct,
            description: this.props.answer.description,
            who_scored: window.cmState.user.id,
            way_of_thinking: this.props.answer.way_of_thinking_id,
        };

        let modal = null;
        if (this.state.open) {
            modal = (
                <EmptyModal onHide={() => this.setState({ open: false })} show>
                    <div className="container">
                        <div>
                            <h4
                                style={{
                                    display: 'inline-block',
                                    marginRight: '10px',
                                }}
                            >
                                Answer Details
                            </h4>
                            <span>
                                Last Edited By: {user.first_name}{' '}
                                {user.last_name}
                            </span>
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <FormWithChildren
                                key={this.props.answer}
                                submit={this.submit_form}
                                defaults={defaults}
                                deleteUrl={`/remove_question_answer/${this.props.answer.id}/`}
                                redirect={this.props.refresh_all}
                                submit_on_enter={false}
                            >
                                <TextInput name="answer" label="Answer" />
                                <Select
                                    boolean
                                    name="is_correct"
                                    label="Is Correct?"
                                />
                                <Select
                                    name="way_of_thinking"
                                    label="Way Of Thinking"
                                    options={this.props.ways_of_thinking}
                                />
                                <MarkdownInput
                                    name="description"
                                    label="Way to get answer"
                                    rows={4}
                                />
                            </FormWithChildren>
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <PageBreak />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <h5>Mapped Answers (click to remove)</h5>
                            <TextInput
                                handlechange={this.filter}
                                name="group_filter"
                                label="Filter"
                            />
                            <div>{groups}</div>
                        </div>
                    </div>
                </EmptyModal>
            );
        }

        let correct = (
            <Icon icon="times" size="1" style={{ color: '#FF6262' }} />
        );
        if (this.props.answer.is_correct) {
            correct = (
                <Icon icon="check" size="1" style={{ color: '#4DD293' }} />
            );
        }
        const desc = <Markdown text={this.props.answer.description} />;

        const asset_modal = (
            <EmptyModal
                show={this.state.asset_modal}
                onHide={() => this.setState({ asset_modal: false })}
            >
                <AssetFilter
                    assets_by_category={this.props.assets_by_category}
                    setGlobalState={this.assign_asset}
                />
            </EmptyModal>
        );

        const assets = [];
        for (var item of this.props.answer.has_asset) {
            const has_asset = item.questionanswerhasasset;

            assets.push(
                <ExistingAsset
                    has_asset={has_asset}
                    assets_lookup={this.props.assets_lookup}
                    delete_asset={this.delete_asset}
                />
            );
        }

        const tattletale = (
            <Button
                type="primary"
                href={`/tattletale/${this.props.question_id}/?answer=${this.props.answer.id}`}
                target="_blank"
                text="Tattletale"
            />
        );

        let performance = 'N/A';
        if (
            this.props.response_performance['response_groups'] &&
            this.props.response_performance['response_groups'][
                this.props.answer.id
            ]
        ) {
            performance =
                this.props.response_performance['response_groups'][
                    this.props.answer.id
                ];
        }

        return (
            <tr>
                <td style={{ width: '25%' }}>{this.props.answer.answer}</td>
                <td style={{ width: '25%' }}>
                    {this.props.answer.total_responses}
                </td>

                <td style={{ width: '25%' }}>
                    {correct}
                    {desc}
                    {tattletale}
                </td>

                <td>{performance}</td>

                <td style={{ width: '25%' }}>
                    {edit}
                    {modal}
                </td>

                <td>
                    <div>{assets}</div>
                    <div>
                        <Button
                            type="primary"
                            text="Add Asset"
                            onClick={() => this.setState({ asset_modal: true })}
                            num={this.props.answer.id}
                        />
                    </div>
                    {asset_modal}
                </td>
            </tr>
        );
    }
}

class QuestionAnswerSimple extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            filter: '',
        };

        this.filter = this.filter.bind(this);
        this.submit_form = this.submit_form.bind(this);

        this.assign_all_to_answer = this.assign_all_to_answer.bind(this);
    }

    filter(e) {
        const { value } = e.target;

        this.setState({
            filter: value,
        });
    }

    submit_form(state, callback) {
        const url = `/api/home/questionanswer/${this.props.answer.id}/`;

        const data = {
            answer: state.answer,
        };

        ajaxWrapper('POST', url, data, () => {
            callback(state);
            this.setState({ open: false });
        });
    }

    assign_all_to_answer() {
        const groups = [];
        for (const item of this.props.answer.response_groups) {
            const group = item.studentresponsegroup;
            groups.push(group.id);
        }

        const data = {
            answer_id: this.props.current_answer.id,
            ids: groups,

            search_term: '',
            exclude_term: '',
        };

        this.setState(
            {
                loading: true,
            },
            ajaxWrapper(
                'POST',
                '/assign_all_student_response_groups/',
                data,
                () => this.props.refresh_all()
            )
        );
    }

    render() {
        let assignment = (
            <Button
                type="primary"
                onClick={() => this.props.select_answer(this.props.answer)}
                text="Select Answer"
            />
        );
        if (this.props.current_answer.id == this.props.answer.id) {
            assignment = (
                <Button
                    type="success"
                    onClick={() => this.props.select_answer({})}
                    text="Active (click to deactivate)"
                />
            );
        }

        const edit = (
            <Button
                type="info"
                onClick={() => this.setState({ open: true })}
                text="Details"
            />
        );

        const user = this.props.users[this.props.answer.who_scored_id] || {};

        const groups = [];
        const ordered_groups = this.props.answer.response_groups.sort((a, b) =>
            a.studentresponsegroup.answer.localeCompare(
                b.studentresponsegroup.answer
            )
        );

        for (const item of ordered_groups) {
            const group = item.studentresponsegroup;

            if (
                this.state.filter &&
                group.answer
                    .toLowerCase()
                    .indexOf(this.state.filter.toLowerCase()) == -1
            ) {
                continue;
            }

            groups.push(
                <AssignedResponseGroup
                    key={group.id}
                    response_group={group}
                    users={this.props.users}
                    refresh_all={this.props.refresh_all}
                />
            );
        }

        let correct = (
            <Icon icon="times" size="1" style={{ color: '#FF6262' }} />
        );
        if (this.props.answer.is_correct) {
            correct = (
                <Icon icon="check" size="1" style={{ color: '#4DD293' }} />
            );
        }
        const desc = <Markdown text={this.props.answer.description} />;

        const defaults = {
            answer: this.props.answer.answer,
            who_scored: window.cmState.user.id,
        };

        let modal = null;
        if (this.state.open) {
            const delete_url = `/remove_question_answer/${this.props.answer.id}/`;

            let assign_to_button = null;
            if (this.props.current_answer.id) {
                assign_to_button = (
                    <Button
                        type="danger"
                        deleteType
                        onClick={this.assign_all_to_answer}
                        text={`Assign ALL to ${this.props.current_answer.answer}`}
                    />
                );
            }

            modal = (
                <EmptyModal onHide={() => this.setState({ open: false })} show>
                    <div className="container" style={{ padding: '15px' }}>
                        <div>
                            <h4
                                style={{
                                    display: 'inline-block',
                                    marginRight: '10px',
                                }}
                            >
                                Answer Details
                            </h4>
                            <span>
                                Last Edited By: {user.first_name}{' '}
                                {user.last_name}
                            </span>
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <FormWithChildren
                                key={this.props.answer}
                                submit={this.submit_form}
                                defaults={defaults}
                                deleteUrl={delete_url}
                                redirect={() => this.props.refresh_all()}
                            >
                                <TextInput name="answer" label="Answer" />
                            </FormWithChildren>
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <PageBreak />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            {correct}
                            {desc}
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <PageBreak />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <div style={{ float: 'right' }}>
                                {assign_to_button}
                            </div>
                            <h5>Mapped Answers (click to remove)</h5>

                            <TextInput
                                handlechange={this.filter}
                                name="group_filter"
                                label="Filter"
                            />
                            <div>{groups}</div>
                        </div>
                    </div>
                </EmptyModal>
            );
        }

        let performance = 'N/A';
        if (
            this.props.response_performance['response_groups'] &&
            this.props.response_performance['response_groups'][
                this.props.answer.id
            ]
        ) {
            performance =
                this.props.response_performance['response_groups'][
                    this.props.answer.id
                ];
        }

        return (
            <tr>
                <td style={{ width: '25%' }}>
                    <b>{this.props.answer.answer}</b>
                </td>
                <td style={{ width: '25%' }}>
                    {this.props.answer.total_responses}
                </td>

                <td>{performance}</td>

                <td style={{ width: '25%' }}>
                    {assignment}
                    {edit}
                    {modal}
                </td>
            </tr>
        );
    }
}

class AssignedResponseGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.remove_group = this.remove_group.bind(this);
    }

    remove_group() {
        const { user } = window.cmState;

        const data = {
            parent: null,
            who_scored: user.id,

            search_term: '',
            exclude_term: '',
        };

        ajaxWrapper(
            'POST',
            `/api/home/studentresponsegroup/${this.props.response_group.id}/`,
            data,
            () => this.props.refresh_all()
        );
    }

    render() {
        return (
            <div style={{ marginBottom: '2px' }}>
                <Button
                    text={this.props.response_group.answer}
                    onClick={this.remove_group}
                    type="danger"
                />
            </div>
        );
    }
}

class ResponseGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            assigned: false,
        };

        this.create_question_answer = this.create_question_answer.bind(this);
        this.assign_to_answer = this.assign_to_answer.bind(this);
    }

    create_question_answer() {
        const { user } = window.cmState;

        const data = {
            question: this.props.question_id,
            answer: this.props.response_group.answer,
            who_scored: user.id,
        };

        ajaxWrapper(
            'POST',
            '/api/home/questionanswer/',
            data,
            this.assign_to_answer
        );
    }

    assign_to_answer(current_answer) {
        const { user } = window.cmState;
        const answer_id = current_answer[0].questionanswer.id;

        const data = {
            parent: answer_id,
            who_scored: user.id,

            search_term: this.props.regex_search,
            exclude_term: this.props.regex_exclude,
        };

        ajaxWrapper(
            'POST',
            `/api/home/studentresponsegroup/${this.props.response_group.id}/`,
            data,
            () => this.props.refresh_all(true)
        );

        this.setState(
            {
                assigned: true,
            },
            () => this.props.select_answer(current_answer[0].questionanswer)
        );
    }

    render() {
        let assignment = (
            <Button
                onClick={this.create_question_answer}
                text="Create Answer"
            />
        );

        if (this.state.assigned) {
            assignment = (
                <Button
                    disabled={{ disabled: 'disabled' }}
                    text="Already Assigned"
                />
            );
        } else if (this.props.current_answer.id) {
            assignment = (
                <Button
                    onClick={() =>
                        this.assign_to_answer([
                            { questionanswer: this.props.current_answer },
                        ])
                    }
                    text={`Assign to ${this.props.current_answer.answer}`}
                />
            );
        }

        let exclude = (
            <Button
                type="warning"
                onClick={() =>
                    this.props.exclude_group(this.props.response_group.id)
                }
                text="Exclude"
            />
        );
        if (this.props.excluded.indexOf(this.props.response_group.id) > -1) {
            exclude = (
                <Button
                    type="danger"
                    onClick={() =>
                        this.props.exclude_group(this.props.response_group.id)
                    }
                    text="Excluded!"
                />
            );
        }

        let tattletale_group = (
            <Button
                type="primary"
                href={`/tattletale/${this.props.question_id}/?group=${this.props.response_group.id}`}
                target="_blank"
                text="Tattletale"
            />
        );

        let performance = 'N/A';
        if (
            this.props.response_performance['question_answers'] &&
            this.props.response_performance['question_answers'][
                this.props.response_group.id
            ]
        ) {
            performance =
                this.props.response_performance['question_answers'][
                    this.props.response_group.id
                ];
        }

        return (
            <tr>
                <td style={{ maxWidth: '400px' }}>
                    {this.props.response_group.answer}
                </td>
                <td>{this.props.response_group.total_responses}</td>
                <td>{performance}</td>
                <td>
                    {assignment}
                    {exclude}
                    {tattletale_group}
                </td>
            </tr>
        );
    }
}

class StudentResponseGrouping extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            loading: false,

            question: null,
            sorted_answers: [],

            existing_answers: [],
            response_groups: [],

            excluded_response_groups: [],
            response_groups_total: 0,

            users: {},

            assessment: '',
            district: '',
            regex_search: '',
            regex_exclude: '',

            existing_answer_search: '',
            show_all_answers: false,
            current_answer: {},

            assets_by_category: {},
            assets_lookup: {},
            sorting: 'frequency',

            min_value: '',
            max_value: '',

            response_performance: { question_answers: {}, response_groups: {} },

            offset: 0,
            limit: 100,

            mode: '',

            question_scoring_triggered: false,
            updating_question_scoring: false,
            correct_computations: false,
            ways_of_thinking: [],
        };

        this.refresh_all = this.refresh_all.bind(this);
        this.refresh_question = this.refresh_question.bind(this);
        this.refresh_responses = this.refresh_responses.bind(this);
        this.load_question = this.load_question.bind(this);
        this.load_responses = this.load_responses.bind(this);
        this.load_performance = this.load_performance.bind(this);
        this.load_users = this.load_users.bind(this);
        this.load_ways_of_thinking = this.load_ways_of_thinking.bind(this);

        this.change_offset = this.change_offset.bind(this);
        this.filter_answers = this.filter_answers.bind(this);
        this.filter_groups = this.filter_groups.bind(this);
        this.select_answer = this.select_answer.bind(this);

        this.assign_all_groups = this.assign_all_groups.bind(this);
        this.exclude_group = this.exclude_group.bind(this);
        this.clear_excluded = this.clear_excluded.bind(this);

        this.assets_callback = this.assets_callback.bind(this);
        this.update_question_scoring = this.update_question_scoring.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            '/api/user/user/?role__in=Analyst,Manager Analyst,Super Admin',
            {},
            this.load_users
        );
        ajaxWrapper(
            'GET',
            '/api/actionable_assets/category/?related=assets',
            {},
            this.assets_callback
        );
        ajaxWrapper(
            'GET',
            '/api/home/wayofthinking/?order_by=order&question=' +
                this.props.question_id,
            {},
            this.load_ways_of_thinking
        );

        ajaxWrapper(
            'POST',
            `/student_response_group_performance/${this.props.question_id}/`,
            {},
            this.load_performance
        );
    }

    assets_callback(value) {
        const assets_lookup = {};
        for (const item of value) {
            for (const jtem of item.category.assets) {
                const { asset } = jtem;
                assets_lookup[asset.id] = asset;
            }
        }

        this.setState(
            {
                assets_by_category: value,
                assets_lookup,
            },
            () => this.refresh_all()
        );
    }

    refresh_question() {
        ajaxWrapper(
            'GET',
            `/api/home/question/${this.props.question_id}/?related=answers,answers__response_groups,answers__has_asset`,
            {},
            this.load_question
        );
    }

    load_question(result) {
        const { question } = result[0];

        var sorted_answers = [];
        for (const item of question.answers) {
            const answer = item.questionanswer;
            let total_responses = 0;
            for (const jtem of answer.response_groups) {
                const group = jtem.studentresponsegroup;
                total_responses += group.total_responses;
            }

            answer.total_responses = total_responses;
        }
        var sorted_answers = question.answers.sort(
            (a, b) =>
                b.questionanswer.total_responses -
                a.questionanswer.total_responses
        );

        this.setState(
            {
                loaded: true,
                question: question,
                sorted_answers,
            },
            () => {
                this.scroll_fix();
            }
        );
    }

    load_users(result) {
        const users = {};
        for (const index in result) {
            const { user } = result[index];
            users[user.id] = user;
        }

        this.setState({ users });
    }

    load_ways_of_thinking(result) {
        const ways_of_thinking = [];
        for (var way of result) {
            var way_of_thinking = way.wayofthinking;
            ways_of_thinking.push({
                value: way_of_thinking.id,
                text: way_of_thinking.name,
            });
        }

        this.setState({ ways_of_thinking: ways_of_thinking });
    }

    refresh_all(just_question) {
        const scrollTop = window.scrollY;

        this.setState(
            {
                scroll: scrollTop,
            },
            () => {
                this.refresh_question();
                if (!just_question) {
                    this.refresh_responses();
                }
            }
        );
    }

    refresh_responses() {
        let timestamp = Date.now();
        const data = {
            assessment: this.state.assessment,
            district: this.state.district,

            regex_search: this.state.regex_search,
            regex_exclude: this.state.regex_exclude,

            limit: this.state.limit,
            offset: this.state.offset,

            sorting: this.state.sorting,

            min_value: this.state.min_value,
            max_value: this.state.max_value,

            timestamp: timestamp,
        };

        if (
            !regexCheck(this.state.regex_search) ||
            !regexCheck(this.state.regex_exclude)
        ) {
            return false;
        }

        this.setState(
            {
                loading: true,
                timestamp: timestamp,
            },
            () => {
                ajaxWrapper(
                    'POST',
                    `/student_response_groups/${this.props.question_id}/`,
                    data,
                    this.load_responses
                );
            }
        );
    }

    load_responses(result) {
        if ('error' in result) {
            this.setState({
                error: result.error,
            });

            return false;
        }

        if (result['timestamp'] != this.state.timestamp) {
            return false;
        }

        this.setState(
            {
                response_groups: result.response_groups,
                response_groups_total: result.total,
                loading: false,
                error: null,
            },
            () => {
                this.scroll_fix();
            }
        );
    }

    load_performance(result) {
        if ('error' in result) {
            this.setState({
                error: result.error,
            });

            return false;
        }

        this.setState({
            response_performance: result,
        });
    }

    scroll_fix() {
        if (!this.state.loaded || this.state.loading) {
            return false;
        }

        setTimeout(() => {
            window.scrollTo(0, this.state.scroll);
        }, 100);
    }

    change_offset(change) {
        this.setState(
            {
                offset: this.state.offset + change,
            },
            this.refresh_responses
        );
    }

    filter_answers(name, state) {
        this.setState(state);
    }

    filter_groups(name, state) {
        let changed = false;
        for (const key in state) {
            if (key != 'defaults' && state[key] != this.state[key]) {
                changed = true;
            }
        }

        if (changed) {
            this.setState(state, this.refresh_responses);
        }
    }

    select_answer(value) {
        this.setState({
            current_answer: value,
        });
    }

    assign_all_groups() {
        let assigned_responses = [];
        for (var item of this.state.question.answers) {
            for (var response of item.questionanswer.response_groups) {
                assigned_responses.push(response.studentresponsegroup.id);
            }
        }

        const ids = [];
        for (const item of this.state.response_groups) {
            const group = item.studentresponsegroup;
            if (
                this.state.excluded_response_groups.indexOf(group.id) == -1 &&
                !assigned_responses.includes(group.id)
            ) {
                ids.push(group.id);
            }
        }

        const data = {
            answer_id: this.state.current_answer.id,
            ids,

            search_term: this.state.regex_search,
            exclude_term: this.state.regex_exclude,
        };

        this.setState(
            {
                loading: true,
            },
            ajaxWrapper(
                'POST',
                '/assign_all_student_response_groups/',
                data,
                () => this.refresh_all()
            )
        );
    }

    exclude_group(id) {
        const index = this.state.excluded_response_groups.indexOf(id);
        if (index > -1) {
            this.state.excluded_response_groups.splice(index, 1);
        } else {
            this.state.excluded_response_groups.push(id);
        }

        this.setState({
            excluded_response_groups: this.state.excluded_response_groups,
        });
    }

    clear_excluded() {
        this.setState({
            excluded_response_groups: [],
        });
    }

    update_question_scoring(event, callback) {
        callback();

        this.setState({
            updating_question_scoring: true,
            question_scoring_triggered: true,
        });

        ajaxWrapper(
            'POST',
            `/update_question_scoring/${this.props.question_id}/`,
            {},
            function (result) {
                this.setState({ updating_question_scoring: false });
            }.bind(this)
        );
    }

    render() {
        // wrangle the data and give it to the sortable/comparison tables.
        let header = null;
        let answers_jsx = null;
        let answers_search_jsx = null;
        let responses_jsx = null;

        const context = {
            question_id: this.props.question_id,
            refresh_all: this.refresh_all,

            regex_search: this.state.regex_search,
            regex_exclude: this.state.regex_exclude,

            current_answer: this.state.current_answer,
            excluded: this.state.excluded_response_groups,
            exclude_group: this.exclude_group,

            assets_by_category: this.state.assets_by_category,
            assets_lookup: this.state.assets_lookup,
            response_performance: this.state.response_performance,
            ways_of_thinking: this.state.ways_of_thinking,
        };

        let update_scoring_modal = null;
        if (
            this.state.question_scoring_triggered ||
            this.state.updating_question_scoring
        ) {
            update_scoring_modal = (
                <EmptyModal
                    show={true}
                    onHide={() =>
                        this.setState({ question_scoring_triggered: false })
                    }
                >
                    <Container style={{ marginTop: '40px' }}>
                        {this.state.updating_question_scoring ? (
                            <Alert
                                type="warning"
                                text={
                                    <h4>
                                        {
                                            'Scoring updating. DO NOT LEAVE THIS PAGE!'
                                        }
                                    </h4>
                                }
                            />
                        ) : null}
                        <Wrapper
                            loaded={!this.state.updating_question_scoring}
                            style={{ margin: '0px' }}
                        >
                            <Alert
                                type="success"
                                text={
                                    <h4>
                                        {
                                            'Scoring updated successfully.  You may now leave/reload the page.'
                                        }
                                    </h4>
                                }
                            />
                        </Wrapper>
                    </Container>
                </EmptyModal>
            );
        }

        if (this.state.loaded) {
            const responses = [];
            const question_answers = [];
            const question_answers_simple = [];

            for (var item of this.state.sorted_answers) {
                const answer = item.questionanswer;

                if (this.state.existing_answer_search) {
                    if (
                        answer.answer
                            .toLowerCase()
                            .indexOf(
                                this.state.existing_answer_search.toLowerCase()
                            ) > -1 ||
                        this.state.current_answer.id == answer.id
                    ) {
                        question_answers.push(
                            <QuestionAnswer
                                key={answer.id}
                                answer={answer}
                                users={this.state.users}
                                {...context}
                            />
                        );
                        question_answers_simple.push(
                            <QuestionAnswerSimple
                                key={answer.id}
                                answer={answer}
                                select_answer={this.select_answer}
                                users={this.state.users}
                                {...context}
                            />
                        );
                    }
                } else if (
                    question_answers.length < 10 ||
                    this.state.show_all_answers
                ) {
                    question_answers.push(
                        <QuestionAnswer
                            key={answer.id}
                            answer={answer}
                            users={this.state.users}
                            {...context}
                        />
                    );
                    question_answers_simple.push(
                        <QuestionAnswerSimple
                            key={answer.id}
                            answer={answer}
                            select_answer={this.select_answer}
                            users={this.state.users}
                            {...context}
                        />
                    );
                }
            }

            for (var item of this.state.response_groups) {
                const response_group = item.studentresponsegroup;

                responses.push(
                    <ResponseGroup
                        key={response_group.id}
                        response_group={response_group}
                        {...context}
                        select_answer={this.select_answer}
                    />
                );
            }

            let assign_all = (
                <Button
                    type="danger"
                    deleteType
                    text="Create Answer For ALL"
                    onClick={this.assign_all_groups}
                />
            );
            if (this.state.current_answer.id) {
                assign_all = (
                    <Button
                        type="warning"
                        deleteType
                        text={`Assign All Groups to ${this.state.current_answer.answer}`}
                        onClick={this.assign_all_groups}
                    />
                );
            }

            let clear_excluded = null;
            if (this.state.excluded_response_groups.length > 0) {
                clear_excluded = (
                    <Button
                        type="info"
                        deleteType
                        text="Clear Excluded"
                        onClick={this.clear_excluded}
                    />
                );
            }

            let prev_btn = null;
            if (this.state.offset > 0) {
                prev_btn = (
                    <Button
                        type="danger"
                        text="Previous 100"
                        onClick={() => this.change_offset(-100)}
                    />
                );
            }

            const buttons = (
                <tr>
                    <td>
                        {prev_btn}
                        <Button
                            type="success"
                            text="Next 100"
                            onClick={() => this.change_offset(100)}
                        />
                        <Button
                            type="info"
                            text="Refresh"
                            onClick={this.refresh_responses}
                        />
                    </td>
                    <td />
                    <td>
                        {assign_all}
                        {clear_excluded}
                    </td>
                </tr>
            );

            let error = null;
            if (this.state.error) {
                error = <Alert type="danger" text={this.state.error} />;
            }

            var defaults = {
                existing_answer_search: this.state.existing_answer_search,
            };

            let toggle_all = (
                <Button
                    style={{ marginTop: '32px' }}
                    className="col-6"
                    type="success"
                    text="Show All Answers"
                    onClick={() =>
                        this.setState({
                            show_all_answers: !this.state.show_all_answers,
                        })
                    }
                />
            );
            if (this.state.show_all_answers) {
                toggle_all = (
                    <Button
                        style={{ marginTop: '32px' }}
                        className="col-6"
                        type="warning"
                        text="Hide All Answers"
                        onClick={() =>
                            this.setState({
                                show_all_answers: !this.state.show_all_answers,
                            })
                        }
                    />
                );
            }

            let mode_button = (
                <Button
                    onClick={() => this.setState({ mode: 'answer_details' })}
                    text="Switch To Answer Detail Mode"
                    type="primary"
                />
            );
            if (this.state.mode == 'answer_details') {
                mode_button = (
                    <Button
                        onClick={() => this.setState({ mode: '' })}
                        text="Switch To Group Mapping Mode"
                        type="primary"
                    />
                );
            }

            let question_correct_answer = null;
            if (this.state.question && this.state.question.correct_answer) {
                question_correct_answer = (
                    <Alert
                        type="success"
                        text={`Correct Answer: ${this.state.question.correct_answer}`}
                    />
                );
            }

            var download = <Button
                onClick={() => this.setState({ show_download: true })}
                text="Download Data"
                type="primary"
            />
            if (this.state.show_download) {
                download = <DownloadModal
                    key={this.state.question.id + '_download'}
                    url={`/download_student_question_data/${this.state.question.id}/`}
                    query={{}}
                    onHide={() => this.setState({ show_download: false })}
                    filename = {'QuestionData_' + this.state.question.name + '.csv'}
                />
            }

            header = (
                <div>
                    <div style={{ padding: '0px 15px' }}>
                        {error}
                        <div
                            style={{
                                display: 'inline-block',
                                marginRight: '10px',
                                verticalAlign: 'top',
                            }}
                        >
                            <QuestionImage question={this.state.question} />
                        </div>
                        <div style={{ display: 'inline-block' }}>
                            <h3 style={{ maxWidth: '800px' }}>
                                {this.state.question.name}
                            </h3>
                            <div>
                                {question_correct_answer}
                                {mode_button}
                                <Button
                                    type="danger"
                                    deleteType
                                    delete_text="Do NOT Score"
                                    delete_warning_text={
                                        'Are you sure you want to score the question?  \
                                        It can take up to 5 mins for the grading to complete.  \
                                        DO NOT LEAVE THIS PAGE!'
                                    }
                                    text="Update Question Scoring"
                                    onClick={this.update_question_scoring}
                                />
                                <Button
                                    type="dark"
                                    href={
                                        '/ka_ways_of_thinking/' +
                                        this.props.question_id +
                                        '/'
                                    }
                                    text="Ways Of Thinking"
                                />
                                <Button
                                    type="light"
                                    onClick={() =>
                                        this.setState({
                                            correct_computations: true,
                                        })
                                    }
                                    text="Assign Correct Computations"
                                />
                                {download}
                                <EmptyModal
                                    show={this.state.correct_computations}
                                    onHide={() =>
                                        this.setState({
                                            correct_computations: false,
                                        })
                                    }
                                >
                                    <CorrectComputations
                                        question_id={this.props.question_id}
                                    />
                                </EmptyModal>
                            </div>
                        </div>
                    </div>

                    <div style={{ margin: '20px 0px 40px' }}>
                        <PageBreak />
                    </div>
                </div>
            );

            answers_jsx = (
                <div className="container">
                    <div>
                        <div>
                            <h3
                                style={{
                                    display: 'inline-block',
                                    marginRight: '10px',
                                }}
                            >
                                Existing Answers
                            </h3>
                            <span>
                                {`(${this.state.question.answers.length} total)`}
                            </span>
                        </div>

                        <div>
                            <FormWithChildren
                                row
                                autoSetGlobalState
                                setGlobalState={this.filter_answers}
                                globalStateName="answer_filters"
                            >
                                <TextInput
                                    className="col-6"
                                    label="Answer Search"
                                    name="existing_answer_search"
                                />
                                {toggle_all}
                            </FormWithChildren>
                        </div>
                        <table className="table">
                            <tr>
                                <th>Answer</th>
                                <th>Total Responses</th>
                                <th>Description</th>
                                <th>Edit</th>
                                <th>Sensemaking</th>
                            </tr>
                            {question_answers}
                        </table>
                    </div>
                </div>
            );

            answers_search_jsx = (
                <div>
                    <div>
                        <div>
                            <h3
                                style={{
                                    display: 'inline-block',
                                    marginRight: '10px',
                                }}
                            >
                                Existing Answers
                            </h3>
                            <span>
                                {`(${this.state.question.answers.length} total)`}
                            </span>
                        </div>

                        <div>
                            <FormWithChildren
                                row
                                autoSetGlobalState
                                setGlobalState={this.filter_answers}
                                globalStateName="answer_filters"
                            >
                                <TextInput
                                    className="col-6"
                                    label="Answer Search"
                                    name="existing_answer_search"
                                />
                                {toggle_all}
                            </FormWithChildren>
                        </div>
                    </div>

                    <Wrapper
                        loaded={this.state.loaded}
                        style={{ margin: '0px' }}
                    >
                        <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                            <table className="table">
                                <tr>
                                    <th>Answer</th>
                                    <th>#</th>
                                    <th>Average Student Score</th>
                                    <th>Details</th>
                                </tr>

                                {question_answers_simple}
                            </table>
                        </div>
                    </Wrapper>
                </div>
            );

            let filter_group_defaults = {
                regex_search: this.state.regex_search,
                regex_exclude: this.state.regex_exclude,
                assessment: this.state.assessment,
                limit: this.state.limit,
                sorting: this.state.sorting,
                math_filter_type: this.state.math_filter_type,
                math_filter_value: this.state.math_filter_value,
            };

            responses_jsx = (
                <div>
                    <div>
                        <div>
                            <h3
                                style={{
                                    display: 'inline-block',
                                    marginRight: '10px',
                                }}
                            >
                                Response Groups
                            </h3>
                            <span>
                                {`(${this.state.response_groups_total} Groups Matching Filters)`}
                            </span>
                        </div>
                        <FormWithChildren
                            row
                            autoSetGlobalState
                            setGlobalState={this.filter_groups}
                            globalStateName="group_filters"
                            defaults={filter_group_defaults}
                        >
                            <TextInput
                                className="col-4"
                                label="Regex Search"
                                name="regex_search"
                                is_regex
                            />
                            <TextInput
                                className="col-4"
                                label="Regex Exclude"
                                name="regex_exclude"
                                is_regex
                            />

                            <NumberInput
                                className="col-2"
                                label="Minimum Value"
                                name="min_value"
                            />
                            <NumberInput
                                className="col-2"
                                label="Maximum Value"
                                name="max_value"
                            />

                            <Select
                                className="col-4"
                                optionsUrl={`/api/home/assessment/?order_by=-created_at&questions_on_assessments__question=${this.props.question_id}`}
                                optionsUrlMap={{
                                    text: '{assessment.name}',
                                    value: '{assessment.id}',
                                }}
                                name="assessment"
                                label="Assessment"
                            />
                            <Select
                                className="col-4"
                                optionsUrl={`/api/sis/district/?order_by=name`}
                                optionsUrlMap={{
                                    text: '{district.name}',
                                    value: '{district.id}',
                                }}
                                name="district"
                                label="District"
                            />

                            <NumberInput
                                className="col-2"
                                label="Limit"
                                name="limit"
                            />

                            <Select
                                className="col-2"
                                label="Sorting"
                                name="sorting"
                                options={[
                                    { text: 'frequency', value: 'frequency' },
                                    {
                                        text: 'alphabetically',
                                        value: 'alphabetically',
                                    },
                                ]}
                            />
                        </FormWithChildren>
                    </div>

                    <Wrapper
                        loaded={!this.state.loading}
                        style={{ margin: '0px' }}
                    >
                        <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                            <table className="table">
                                <tr>
                                    <th>Answer</th>
                                    <th>#</th>
                                    <th>Average Student Score</th>
                                    <th>Answer Mapping</th>
                                </tr>

                                {buttons}
                                {responses}
                                {buttons}
                            </table>
                        </div>
                    </Wrapper>
                </div>
            );
        }

        var defaults = {
            regex_search: this.state.regex_search,
            regex_exclude: this.state.regex_exclude,
            assessment: this.state.assessment,
            limit: this.state.limit,
        };

        if (this.state.mode == 'answer_details') {
            var content = (
                <div style={{ marginTop: '40px' }}>
                    {header}

                    <div className="row">
                        <div className="col-12">
                            <Wrapper
                                loaded={this.state.loaded}
                                content={answers_jsx}
                                style={{ margin: '0px' }}
                            />
                        </div>
                    </div>

                    <div style={{ marginTop: '40px' }} />
                </div>
            );
        } else {
            var content = (
                <div style={{ marginTop: '40px' }}>
                    {update_scoring_modal}

                    {header}

                    <div className="row">
                        <div className="col-8">{responses_jsx}</div>

                        <div className="col-4">{answers_search_jsx}</div>
                    </div>

                    <div style={{ marginTop: '40px' }} />
                </div>
            );
        }

        return content;
    }
}

export default StudentResponseGrouping;
