import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Alert,
    FormWithChildren,
    TextInput,
    TextArea,
    Container,
} from 'library';

export default class FindingAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sent: false,
            error: '',
            email: window.cmState.user.email,
            id: window.cmState.user.id,
            message: '',
        };

        this.submit = this.submit.bind(this);
    }

    submit(state, question_id) {
        if (this.state.message === '') {
            this.setState({ error: 'Message field required' });
            return;
        }
        this.setState({ error: '' });

        let text = `<p>User's Email: ${  state.email  }</p>`;
        text += `<p>User's Id: ${  state.id  }</p>`;
        text += `<p>Question ID: ${  question_id  }</p>`;
        text += `<p>Way to Get: ${  state.message  }</p>`;
        text += `<p>Answer: ${  this.props.answer  }</p>`;
        if (this.props.image) text += `<img src="${  this.props.image  }" />`;

        ajaxWrapper(
            'POST',
            '/api/email/',
            {
                to_email: 'support@mathanex.com',
                from_email: state.email,
                subject: 'Math Anex: Ways to get answer',
                text,
            },
            () => this.setState({ sent: true })
        );
    }

    render() {
        const {question_id} = this.props;
        let error = null;
        if (this.state.error != '') {
            error = <Alert type="danger" text={this.state.error} />;
        } else if (this.state.sent) {
            error = <Alert type="success" text="Message Sent." />;
        }

        const {user} = window.cmState;
        const data = {
            email: user.email,
            id: user.id,
        };
        return (
            <Container style={{ padding: '20px' }}>
                <div style={{ fontSize: '20px' }}>
                    What is your way of finding the answer?
                </div>
                <FormWithChildren
                    submit={() => {
                        this.submit(this.state, question_id);
                    }}
                    submit_text="Send Request"
                    submitClassName="purple-button"
                    submit_on_enter={false}
                    defaults={data}
                >
                    <TextInput name="email" style={{ display: 'none' }} />
                    <TextInput name="id" style={{ display: 'none' }} />

                    <div style={{ fontSize: '16px', marginBottom: '5px' }}>
                        Write down the answer...
                    </div>
                    <TextArea
                        handlechange={e =>
                            this.setState({ message: e.target.value })
                        }
                        rows={3}
                        name="message"
                    />
                </FormWithChildren>
                {error}
            </Container>
        );
    }
}
