
const HEADER_HEIGHT = 62;

const MATH_ANEX_EMPLOYEE_ROLES = [
  'Super Admin',
  'Analyst',
  'Manager Analyst',
];

const DISTRICT_STAFF_ROLES = [
  'District Leader',
  'Principal',
  'District Admin',
  'Staff',
];

export {
    HEADER_HEIGHT,
    MATH_ANEX_EMPLOYEE_ROLES,
    DISTRICT_STAFF_ROLES,
}