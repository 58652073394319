import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Button, FormWithChildren, TextInput, TextArea, Alert } from 'library';

export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            message: '',
            sent: false,
            error: '',
        };

        this.send_email = this.send_email.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    send_email(state) {
        if (this.state.email == '' || this.state.message == '') {
            let error = '';
            if (this.state.email == '') {
                error +=
                    'Please enter an email address that so that we can get in touch and help out. ';
            }
            if (this.state.message == '') {
                error += 'Please enter a message so we know how to help.';
            }
            this.setState({ error });
        } else {
            this.setState({ error: '' });
            ajaxWrapper(
                'POST',
                '/api/email/',
                {
                    to_email: 'jeremy.thiesen1@gmail.com',
                    from_email: 'jeremy.thiesen1@gmail.com',
                    subject: 'Math Anex Landing Page',
                    text: `<p>Email: ${this.state.email}</p><p>School or District: ${this.state.school}</p><p>Role: ${this.state.role}</p><p>How Can We Help?: ${this.state.message}</p>`,
                },
                () => this.setState({ sent: true })
            );
        }
    }

    render() {
        let button = (
            <Button onClick={this.send_email} text="Submit" type="success" />
        );
        if (this.state.sent) {
            button = <p>Message Sent.</p>;
        }

        var error = null;
        if (this.state.error != '') {
            var error = <Alert type="danger" text={this.state.error} />;
        }

        return (
            <div className="site-wrapper overflow-hidden position-relative">
                <header className="site-header site-header--menu-right landing-14-menu site-header--absolute site-header--sticky">
                    <div className="container">
                        <nav className="navbar site-navbar">
                            <div className="brand-logo">
                                <a href="/landing/">
                                    <img
                                        src="https://mathanex-media.learning.amplify.com/math_anex_logo.png"
                                        alt=""
                                        style={{ height: '40px' }}
                                    />
                                </a>
                            </div>
                            <div>
                                <h4>Math ANEX</h4>
                            </div>
                            <div className="menu-block-wrapper">
                                <div className="menu-overlay" />
                                <nav
                                    className="menu-block"
                                    id="append-menu-header"
                                >
                                    <div className="mobile-menu-head">
                                        <div className="go-back">
                                            <i className="fa fa-angle-left" />
                                        </div>
                                        <div className="current-menu-title" />
                                        <div className="mobile-menu-close">
                                            &times;
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div className="ms-auto d-none d-xs-inline-flex">
                                <a
                                    className="btn btn-success focus-reset"
                                    href="/landing/"
                                >
                                    Home
                                </a>
                            </div>
                            <div className="ms-auto d-none d-xs-inline-flex">
                                <a
                                    target="_blank"
                                    className="btn btn-primary focus-reset"
                                    href="/login/"
                                >
                                    Log In
                                </a>
                            </div>

                            <div className="mobile-menu-trigger">
                                <span />
                            </div>
                        </nav>
                    </div>
                </header>

                <div className="">
                    <div className="container position-relative">
                        <div className="" />
                        <div className="row justify-content-center text-lg-start text-center">
                            <div className="col-lg-6">
                                <div
                                    className="cta-content"
                                    style={{ textAlign: 'left' }}
                                >
                                    <h2 style={{ color: 'black' }}>
                                        Get In Touch
                                    </h2>
                                    <FormWithChildren
                                        autoSetGlobalState
                                        setGlobalState={this.setGlobalState}
                                        globalStateName="contact-us"
                                    >
                                        <TextInput
                                            name="email"
                                            label="Email"
                                            required
                                        />
                                        <TextInput
                                            name="school"
                                            label="School or District (if applicable)"
                                        />
                                        <TextInput
                                            name="role"
                                            label="Your Role"
                                        />
                                        <TextArea
                                            rows={3}
                                            name="message"
                                            label="How Can We Help?"
                                            required
                                        />
                                    </FormWithChildren>

                                    {button}
                                    {error}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
