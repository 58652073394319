import React from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Container, Wrapper, PageBreak, Alert, NumberInput } from 'library';
import QuestionEnlargeImage from 'pages/questions/enlarge_image.js';
import ListControls from 'pages/reports/results_explorer/components/list_controls.js';

const OFFSET_CHANGE = 40;
const MINIMUM_CONFIDENCE = 95;

class Conflict extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            conflict: null,
        };

        this.resolve_conflict = this.resolve_conflict.bind(this);
    }

    resolve_conflict(bool) {
        this.setState({ conflict: bool });
        this.props.resolve_conflict(this.props.conflict, bool);
    }

    render() {
        const { conflict } = this.props;

        const percent_style = {
            float: 'right',
        };

        let has_component = (
            <div
                className="alert alert-danger"
                style={{ display: 'inline-block' }}
            >
                <span>
                    Nyckel thinks it should <b>NOT</b> have component
                </span>
            </div>
        );
        if (conflict.has_component) {
            has_component = (
                <div
                    className="alert alert-success"
                    style={{ display: 'inline-block' }}
                >
                    <span>Nyckel thinks it should have component</span>
                </div>
            );
        }

        const analyst_correct = (
            <div
                className="btn btn-secondary"
                onClick={() => this.resolve_conflict(false)}
            >
                Analyst is correct
            </div>
        );
        const nyckel_correct = (
            <div
                className="btn btn-primary"
                onClick={() => this.resolve_conflict(true)}
            >
                Nyckel is correct
            </div>
        );

        let conflict_resolution = (
            <div style={{ padding: '10px' }}>
                {analyst_correct} {nyckel_correct}
            </div>
        );
        if (
            conflict.wins_scoring_conflict === true ||
            this.state.conflict === true
        ) {
            conflict_resolution = (
                <div style={{ padding: '10px' }}>Nyckel Wins!</div>
            );
        } else if (
            conflict.wins_scoring_conflict === false ||
            this.state.conflict == false
        ) {
            conflict_resolution = (
                <div style={{ padding: '10px' }}>Analyst Wins!</div>
            );
        }

        return (
            <div className="simple-card">
                <div style={percent_style}>{`${conflict.confidence}%`}</div>
                <div>
                    <h5>{conflict.response.answer}</h5>
                </div>
                <div>{has_component}</div>
                {conflict_resolution}
            </div>
        );
    }
}

class ScoreAllAssessment extends React.Component {
    constructor(props) {
        super(props);

        this.score_all = this.score_all.bind(this);
    }

    score_all() {
        this.props.score_all(this.props.assessment);
    }

    render() {
        const { assessment } = this.props;
        return (
            <div>
                <div
                    style={{ marginBottom: '5px' }}
                    className="btn btn-danger"
                    onClick={this.score_all}
                    deleteType
                >
                    {`${assessment.name}: Score ${assessment.count} Unscored Responses`}
                </div>
            </div>
        );
    }
}

export default class FunctionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            loading: false,
            conflict_loading: false,

            confidence: 99,

            offset: 0,

            component: {},
            predictions: [],
            conflicts: [],
        };

        this.refresh_data = this.refresh_data.bind(this);
        this.load_context = this.load_context.bind(this);

        this.get_predictions = this.get_predictions.bind(this);
        this.change_offset = this.change_offset.bind(this);
        this.load_predictions = this.load_predictions.bind(this);

        this.get_conflicts = this.get_conflicts.bind(this);
        this.load_conflicts = this.load_conflicts.bind(this);

        this.approve_function = this.approve_function.bind(this);
        this.set_function = this.set_function.bind(this);
        this.resolve_conflict = this.resolve_conflict.bind(this);

        this.change_confidence = this.change_confidence.bind(this);
        this.score_all = this.score_all.bind(this);
    }

    componentDidMount() {
        this.refresh_data();
    }

    refresh_data() {
        ajaxWrapper(
            'GET',
            `/nyckel/function_overview/${this.props.function_id}/`,
            {},
            this.load_context
        );
    }

    load_context(value) {
        this.setState(
            {
                function: value,
            },
            this.get_predictions
        );
    }

    get_predictions() {
        let url = '/api/nyckel/ResponseComponentPrediction/?related=response';
        url += `&function=${this.props.function_id}&confidence__gte=${this.state.confidence}&annotated=false&response__active=true`;
        url += `&order_by=confidence,nyckel_id&limit=${OFFSET_CHANGE}&offset=${this.state.offset}`;

        this.setState(
            {
                loading: true,
            },
            () => ajaxWrapper('GET', url, {}, this.load_predictions)
        );
    }

    change_offset(absolute, change) {
        if (absolute > -1) {
            this.setState(
                {
                    offset: absolute,
                },
                this.get_predictions
            );
        } else if (change) {
            this.setState(
                {
                    offset: this.state.offset + change,
                },
                this.get_predictions
            );
        }
    }

    load_predictions(value) {
        if (this.state.loaded) {
            this.setState({
                predictions: value,
                loading: false,
            });
        } else {
            this.setState(
                {
                    predictions: value,
                    loading: false,
                },
                this.get_conflicts
            );
        }
    }

    get_conflicts() {
        this.setState(
            {
                conflict_loading: true,
                loaded: true,
            },
            () =>
                ajaxWrapper(
                    'GET',
                    `/nyckel/conflicts/${this.props.function_id}/?confidence=${this.state.confidence}`,
                    {},
                    this.load_conflicts
                )
        );
    }

    load_conflicts(value) {
        this.setState({
            conflict_loading: false,
            conflicts: value,
        });
    }

    approve_function(bool) {
        ajaxWrapper(
            'POST',
            `/api/nyckel/NyckelFunction/${this.props.function_id}/`,
            {
                approved_to_assign: bool,
                approved_confidence: this.state.confidence,
            },
            this.set_function
        );
    }

    set_function(value) {
        const nyckel_function = this.state.function;
        nyckel_function.approved_to_assign = value.approved_to_assign;
        nyckel_function.approved_confidence = value.approved_confidence;

        this.setState({
            function: nyckel_function,
        });
    }

    resolve_conflict(conflict, bool) {
        const data = {
            wins_scoring_conflict: bool,
        };

        ajaxWrapper(
            'POST',
            `/nyckel/resolve_conflict/${conflict.id}/`,
            data,
            () => {}
        );
    }

    change_confidence(value) {
        let confidence = value.target.value;
        if (confidence > 100) {
            confidence = 100;
        } else if (confidence < MINIMUM_CONFIDENCE) {
            confidence = MINIMUM_CONFIDENCE;
        }

        if (confidence != this.state.confidence) {
            this.setState(
                {
                    confidence,
                    loaded: false,
                },
                () => this.refresh_data()
            );
        }
    }

    score_all(assessment) {
        const nyckel_function = this.state.function;

        this.setState({
            loaded: false,
        });

        ajaxWrapper(
            'POST',
            `/nyckel/score_all/${nyckel_function.id}/${assessment.id}/`,
            {},
            () => {
                this.refresh_data();
            }
        );
    }

    render() {
        if (!this.state.loaded) {
            return <Wrapper loaded={false} />;
        }

        const percent_style = {
            float: 'right',
        };

        const nyckel_function = this.state.function;
        const { component } = nyckel_function;
        const { question } = component.approach;

        const predictions = [];
        for (var item of this.state.predictions) {
            const prediction = item.ResponseComponentPrediction;

            let has_component = (
                <div
                    className="alert alert-danger"
                    style={{ display: 'inline-block' }}
                >
                    <span>No component</span>
                </div>
            );
            if (prediction.has_component) {
                has_component = (
                    <div
                        className="alert alert-success"
                        style={{ display: 'inline-block' }}
                    >
                        <span>Has component</span>
                    </div>
                );
            }

            predictions.push(
                <div className="simple-card">
                    <div style={percent_style}>
                        {`${prediction.confidence}%`}
                    </div>
                    <div>
                        <h5>{prediction.response.answer}</h5>
                    </div>
                    <div>{has_component}</div>
                </div>
            );
        }

        const conflicts = [];
        for (var item of this.state.conflicts) {
            const conflict = item.ResponseComponentPrediction;

            conflicts.push(
                <Conflict
                    conflict={conflict}
                    resolve_conflict={this.resolve_conflict}
                />
            );
        }
        if (conflicts.length == 0) {
            conflicts.push(
                <div style={{ margin: '10px' }}>
                    <Alert type="success">All conflicts are resolved.</Alert>
                </div>
            );
        }

        const component_display = (
            <div>
                <h2>{component.approach.name}</h2>
                <h3>{component.name}</h3>
            </div>
        );

        let approve_button = (
            <div
                className="btn btn-warning"
                onClick={() => this.approve_function(true)}
            >
                {`Approve Function To Score At ${this.state.confidence}%`}
            </div>
        );
        if (nyckel_function.approved_to_assign) {
            approve_button = (
                <div
                    className="btn btn-success"
                    onClick={() => this.approve_function(false)}
                >
                    {`Function Approved To Score Above ${nyckel_function.approved_confidence}%`}
                </div>
            );
        }

        const score_all_buttons = [];
        for (const assessment of this.state.function.assessments) {
            score_all_buttons.push(
                <ScoreAllAssessment
                    score_all={this.score_all}
                    assessment={assessment}
                />
            );
        }

        const list_controls = (
            <ListControls
                student_count={nyckel_function.total_unscored_responses}
                change={OFFSET_CHANGE}
                offset={this.state.offset}
                change_offset={this.change_offset}
            />
        );

        return (
            <Wrapper loaded={this.state.loaded} style={{ margin: '0px' }}>
                <div className="sad" style={{ padding: '30px 0px 50px 0px' }}>
                    <Container min_height>
                        <div className="simple-card">
                            <div className="row">
                                <div className="col-4">
                                    <QuestionEnlargeImage
                                        image={
                                            question.images_by_language.English
                                        }
                                        text={question.text_by_language.English}
                                        height={150}
                                    />
                                </div>
                                <div className="col-6">{component_display}</div>
                            </div>
                        </div>

                        <div className="simple-card">
                            <div className="row">
                                <div className="col-6">
                                    <NumberInput
                                        name="confidence"
                                        label="Confidence %"
                                        value={this.state.confidence}
                                        handleChange={this.change_confidence}
                                    />
                                </div>
                                <div className="col-6">
                                    <div style={{ marginTop: '32px' }}>
                                        {approve_button}
                                    </div>
                                </div>
                                <div className="col-12">
                                    {score_all_buttons}
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: '20px' }}>
                            <PageBreak />
                        </div>

                        <div>
                            <h4 className="simple-card">
                                Conflicts with scored Responses
                            </h4>
                            <Wrapper
                                loaded={!this.state.conflict_loading}
                                style={{ margin: '0px' }}
                            >
                                {conflicts}
                            </Wrapper>
                        </div>

                        <div style={{ padding: '20px' }}>
                            <PageBreak />
                        </div>

                        <div>
                            <div className="simple-card">
                                <h4>Valid Predictions for Responses</h4>
                                <div className="row">
                                    <div className="col-6">
                                        {`Total Has Component: ${nyckel_function.samples_high_confidence_has_component_count}`}
                                    </div>
                                    <div className="col-6">
                                        {`Total Has Not Component: ${nyckel_function.samples_high_confidence_has_not_component_count}`}
                                    </div>
                                </div>
                            </div>

                            <Wrapper
                                loaded={!this.state.loading}
                                style={{ margin: '0px' }}
                            >
                                {predictions}
                                {list_controls}
                            </Wrapper>
                        </div>
                    </Container>
                </div>
            </Wrapper>
        );
    }
}
