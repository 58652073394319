import React, { Component } from 'react';




export default class Landing extends Component {
    render() {
        const districts_using_us = (
            <div className="brand-area-l-14">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h4>
                                Our Assessments and Reporting Tool Are Used By
                                Districts Across The Country
                            </h4>
                        </div>
                    </div>
                    <div className="row img-grayscale">
                        <div className="col-lg-12">
                            <div className="brand-area-l-14-items d-flex justify-content-center justify-content-xl-between align-items-center flex-wrap ">
                                <div
                                    className="single-brand "
                                    data-aos="fade-right"
                                    data-aos-duration="500"
                                    data-aos-once="true"
                                >
                                    <img
                                        src="https://mathanex-media.learning.amplify.com/MathANEXLogo.png"
                                        alt=""
                                    />
                                </div>
                                <div
                                    className="single-brand "
                                    data-aos="fade-right"
                                    data-aos-duration="700"
                                    data-aos-once="true"
                                >
                                    <img
                                        src="https://mathanex-media.learning.amplify.com/MathANEXLogo.png"
                                        alt=""
                                    />
                                </div>
                                <div
                                    className="single-brand "
                                    data-aos="fade-right"
                                    data-aos-duration="900"
                                    data-aos-once="true"
                                >
                                    <img
                                        src="https://mathanex-media.learning.amplify.com/MathANEXLogo.png"
                                        alt=""
                                    />
                                </div>
                                <div
                                    className="single-brand "
                                    data-aos="fade-right"
                                    data-aos-duration="1000"
                                    data-aos-once="true"
                                >
                                    <img
                                        src="https://mathanex-media.learning.amplify.com/MathANEXLogo.png"
                                        alt=""
                                    />
                                </div>
                                <div
                                    className="single-brand "
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                    data-aos-once="true"
                                >
                                    <img
                                        src="https://mathanex-media.learning.amplify.com/MathANEXLogo.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="brand-aarea-border-l14" />
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="site-wrapper overflow-hidden position-relative">
                <header
                    className="site-header site-header--menu-right landing-14-menu site-header--absolute site-header--sticky"
                    style={{
                        background: 'white',
                        boxShadow: '2px 2px 10px rgb(0 0 0 / 10%)',
                        padding: '10px 0px',
                    }}
                >
                    <div className="container">
                        <nav className="navbar site-navbar">
                            <div className="brand-logo">
                                <a href="#">
                                    <div style={{ position: 'relative' }}>
                                        <img
                                            src="https://mathanex-media.learning.amplify.com/MathANEXLogo.png"
                                            alt=""
                                            style={{ height: '50px' }}
                                        />
                                        <div
                                            style={{
                                                top: '2px',
                                                position: 'absolute',
                                                left: '35px',
                                                width: '300px',
                                            }}
                                        >
                                            <h2
                                                style={{
                                                    color: 'black',
                                                    display: 'inline',
                                                    fontSize: '40px',
                                                    marginLeft: '15px',
                                                    fontFamily: 'Rubik',
                                                    fontWeight: 'normal',
                                                }}
                                            >
                                                Math ANEX
                                            </h2>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="menu-block-wrapper">
                                <div className="menu-overlay" />
                                <nav className="menu-block" id="append-menu-header">
                                    <div className="mobile-menu-head">
                                        <div className="go-back">
                                            <i className="fa fa-angle-left" />
                                        </div>
                                        <div className="current-menu-title" />
                                        <div className="mobile-menu-close">
                                            &times;
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div className="ms-auto d-none d-xs-inline-flex">
                                <a
                                    className="btn btn-success focus-reset"
                                    href="/contactUs/"
                                >
                                    Contact Us
                                </a>
                            </div>
                            <div className="ms-auto d-none d-xs-inline-flex">
                                <a
                                    className="btn btn-primary focus-reset"
                                    href="/login/"
                                >
                                    Log In
                                </a>
                            </div>

                            <div className="mobile-menu-trigger">
                                <span />
                            </div>
                        </nav>
                    </div>
                </header>

                <div style={{ width: '100%', height: '20px' }} />
                <div className="hero-area-l-14 position-relative z-index-1 overflow-hidden">
                    <div className="container">
                        <div className="row position-relative justify-content-center">
                            <div
                                className="col-xl-5 col-lg-6 col-md-8 col-sm-10 pr-0 "
                                data-aos="fade-right"
                                data-aos-duration="800"
                                data-aos-once="true"
                            >
                                <div className="content">
                                    <h1>Math ANEX</h1>
                                    <p>
                                        <b>Asset Based Math Assessments</b> that
                                        go beyond measuring what students know
                                        by analyzing how they apply their
                                        knowledge and communicate their
                                        reasoning.
                                    </p>
                                    <a
                                        href="/contactUs/"
                                        className="btn focus-reset"
                                    >
                                        Get In Touch
                                    </a>
                                </div>
                            </div>
                            <div
                                className="col-xl-7 col-lg-5 col-md-8 "
                                data-aos="fade-left"
                                data-aos-duration="800"
                                data-aos-once="true"
                            >
                                <div className="banner-image-l-14">
                                    <img
                                        src="http://upload.wikimedia.org/wikipedia/commons/1/16/Stereographic_polytope_24cell.png"
                                        alt=""
                                        className="w-100 mt-xl-n10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-shape-14" />
                </div>

                <div className="content-area-l-14-1 position-relative">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div
                                className="col-xl-6 col-lg-6 col-md-8 col-sm-10 order-lg-1 order-1"
                                data-aos="fade-right"
                                data-aos-duration="1200"
                                data-aos-once="true"
                            >
                                <div className="h-100 section-heading-8 content text-lg-start text-center">
                                    <h2>Measuring Student Thinking</h2>
                                    <p>
                                        This is at the core of our asset based
                                        approach to assessment. Students are not
                                        blank slates. Partial or misapplied
                                        approaches are the foundations for
                                        students and teachers to reflect upon
                                        and revise their thinking to lead
                                        towards deeper and more robust
                                        understanding.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="offset-xl-2 col-xl-4 col-lg-6 col-md-8 pl-xl-11 order-lg-1 order-0"
                                data-aos="fade-left"
                                data-aos-duration="1200"
                                data-aos-once="true"
                            >
                                <div className="content-img">
                                    <img
                                        src="https://www.acps.lk/wp-content/uploads/2016/Images/why-study-at-aics/Accelerate-Thinking.png"
                                        alt=""
                                        className="w-100 w-xl-auto"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-area-l-14-2">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div
                                className="col-xl-4 col-lg-6 col-md-8"
                                data-aos="fade-right"
                                data-aos-duration="800"
                                data-aos-once="true"
                            >
                                <div className="content-img pr-lg-13">
                                    <img
                                    // a few images like this one pointing to original mathanex
                                        src="http://mathanex.com/static/images/l4/content-img-2.png"
                                        alt=""
                                        className="w-100"
                                    />
                                </div>
                            </div>
                            <div
                                className="col-xl-6 offset-xl-2 col-lg-6 col-md-8 col-sm-10"
                                data-aos="fade-left"
                                data-aos-duration="800"
                                data-aos-once="true"
                            >
                                <div className="content section-heading-8 text-lg-start text-center">
                                    <h2>Unique Reporting For Everyone</h2>
                                    <p>
                                        District Leaders need to see the overall
                                        performance of the schools and how new
                                        initiatives performed. Principals need
                                        to understand how to support their
                                        teachers. Teachers need to understand
                                        how to tailor their classroom to their
                                        students. And Student need to be able to
                                        understand how to leverage what they
                                        have to become better mathematicians. We
                                        provide individualized reporting to
                                        every stakeholder.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="features-area-l-14">
                    <div className="container bg-shape-img-2 position-relative">
                        <div className="row features-area-l-14 justify-content-center mx-0">
                            <div
                                className="col-lg-6 col-md-8 col-sm-10"
                                data-aos="fade-right"
                                data-aos-duration="800"
                                data-aos-once="true"
                            >
                                <div className="content-area  d-flex ">
                                    <div className="circle-85 hover-icon">
                                        <img
                                            src="http://mathanex.com/static/images/l4/f-icon-4.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="content-body">
                                        <h5>We Write Your Assessments</h5>
                                        <p className="mb-0">
                                            Our team of experts has decades of
                                            experience in creating assessments
                                            for every grade that drive students
                                            to think and communicate.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-6 col-md-8 col-sm-10"
                                data-aos="fade-left"
                                data-aos-duration="800"
                                data-aos-once="true"
                            >
                                <div className="content-area  d-flex ">
                                    <div className="circle-85 hover-icon">
                                        <img
                                            src="http://mathanex.com/static/images/l4/f-icon-2.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="content-body">
                                        <h5>We Integrate With Your SIS</h5>
                                        <p className="mb-0">
                                            We integrate with any SIS to make
                                            information security and sharing
                                            easy. All we need is an introduction
                                            to your team.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-6 col-md-8 col-sm-10"
                                data-aos="fade-right"
                                data-aos-duration="800"
                                data-aos-once="true"
                            >
                                <div className="content-area  d-flex ">
                                    <div className="circle-85 hover-icon">
                                        <img
                                            src="http://mathanex.com/static/images/l4/f-icon-1.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="content-body">
                                        <h5>
                                            We Score With A Professionally
                                            Trained Team
                                        </h5>
                                        <p className="mb-0">
                                            We have a team of over 50 analysts
                                            that have been trained specifically
                                            to analyze student explanations and
                                            to understand how students think.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-6 col-md-8 col-sm-10"
                                data-aos="fade-left"
                                data-aos-duration="800"
                                data-aos-once="true"
                            >
                                <div className="content-area  d-flex ">
                                    <div className="circle-85  hover-icon">
                                        <img
                                            src="http://mathanex.com/static/images/l4/f-icon-3.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="content-body">
                                        <h5>We Report Back Our Results</h5>
                                        <p className="mb-0">
                                            Ranging from an online platform to
                                            individualized pdfs or presentations
                                            to your board, we help everyone from
                                            students to district leaders
                                            understand their performance and
                                            what can be done to improve.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cta-area-l-14">
                    <div className="container position-relative">
                        <div className="bg-shape-img-3" />
                        <div className="row cta-area-l-14-content justify-content-center text-lg-start text-center">
                            <div className="col-lg-6">
                                <div className="cta-content">
                                    <h2 className="text-white">
                                        Ready to get started?
                                    </h2>
                                </div>
                            </div>
                            <div className="col-lg-3 text-lg-end text-center">
                                <a className="btn" href="/contactUs/">
                                    Get In Touch
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
