import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { TextInput, Alert, Header, Button, Wrapper } from 'library';

class PasswordResetRequest extends Component {
    constructor(props) {
        super(props);
        this.state = { email: '', error: '', sent: false, loaded: true };
        this.emailCallback = this.emailCallback.bind(this);
        this.email = this.email.bind(this);
        this.userLookup = this.userLookup.bind(this);
        this.check_district_auth = this.check_district_auth.bind(this);
    }

    handleChange = e => {
        const name = e.target.getAttribute('name');
        const newState = {};
        newState[name] = e.target.value;

        const newCompleteState = this.state;
        newCompleteState[name] = e.target.value;
        this.setState(newState);
    };

    userLookup() {
        let emailState = this.state.email;
        if (emailState.indexOf('sandi.net') > -1) {
            ajaxWrapper(
                'POST',
                '/users/check_district_auth/',
                { email: emailState },
                this.check_district_auth
            );
        } else {
            ajaxWrapper(
                'POST',
                '/users/userCheck/',
                { email: emailState },
                this.email
            );
        }
    }

    check_district_auth(result) {
        let emailState = this.state.email;
        if (result.error == null) {
            ajaxWrapper(
                'POST',
                '/users/userCheck/',
                { email: emailState },
                this.email
            );
        } else {
            this.setState({ error: result.error });
        }
    }

    email(result) {
        if (result.length > 0) {
            const { user } = result[0];
            this.setState({ email: user.email });

            ajaxWrapper(
                'POST',
                '/api/email/',
                {
                    to_email: user.email,
                    from_email: 'support@mathanex.com',
                    subject: 'Password Reset',
                    text: `You can reset your password by <a href="http://mathanex.com/passwordReset/${user.password_reset_code}/">clicking here</a>`,
                },
                this.emailCallback
            );
        } else {
            this.setState({
                error: 'No user found with that email. Please Try a Different Email, or Contact Your IT Team or System Administrator',
            });
        }
    }

    emailCallback(result) {
        this.setState({ sent: true });
    }

    render() {
        let emailState = this.state.email;
        const email_props = {
            value: emailState,
            name: 'email',
            label: 'Email:',
            placeholder: 'your_email@school_domain.com',
            handlechange: this.handleChange,
        };
        let errorState = this.state.error;
        let sentState = this.state.sent;
        let alert = <div />;
        if (errorState) {
            alert = <Alert text={errorState} type="danger" />;
        }

        let sent = <div />;
        if (sentState == true) {
            sent = (
                <Alert
                    text={`Your password reset email has been sent to ${emailState}.`}
                    type="success"
                />
            );
        }

        const content = (
            <div className="container">
                <Header
                    size={2}
                    text={
                        "Add your email and we'll send you a link to reset your password."
                    }
                />
                <TextInput {...email_props} />
                <Button
                    type="success"
                    text="Reset Password"
                    onClick={this.userLookup}
                />
                {alert}
                {sent}
            </div>
        );

        return <Wrapper loaded={this.state.loaded} content={content} />;
    }
}
export default PasswordResetRequest;
