import React, { Component } from 'react';

import {
    TextInput,
    Alert,
    AudioInput,
    ImageInput,
    FileInput,
    Icon,
    PageBreak,
} from 'library';

function is_dict(value) {
    return value.constructor == Object;
}
function is_list(value) {
    return value.constructor == Array;
}

const LANGUAGES = ['English', 'Spanish', 'Vietnamese', 'Tagalog'];

class EditableKey extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: false,
            current_value: this.props.value,
        };

        this.change_key = this.change_key.bind(this);
        this.delete_key = this.delete_key.bind(this);
    }

    change_key() {
        this.props.change_key(this.props.value, this.state.current_value);

        this.setState({ edit: false });
    }

    delete_key() {
        this.props.delete_key(this.props.value);

        this.setState({ edit: false });
    }

    render() {
        let display = (
            <span
                onClick={() => this.setState({ edit: true })}
                style={{ cursor: 'pointer' }}
            >
                {`${this.props.value}: `}
            </span>
        );

        if (this.state.edit) {
            display = (
                <div style={{ padding: '10px 0px' }}>
                    <TextInput
                        name="changed_key"
                        value={this.state.current_value}
                        handlechange={e =>
                            this.setState({ current_value: e.target.value })
                        }
                        placeholder="Change Key Here"
                    />
                    <div className="btn btn-primary" onClick={this.change_key}>
                        Set Value
                    </div>
                    <div className="btn btn-danger" onClick={this.delete_key}>
                        Delete Value
                    </div>
                </div>
            );
        }

        return display;
    }
}

class EditableValue extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: false,
            current_value: this.props.value,
        };

        this.change_value = this.change_value.bind(this);
        this.set_state_from_image = this.set_state_from_image.bind(this);
    }

    set_state_from_image(value) {
        if (!value.uploading_from_file_input) {
            this.setState({
                current_value: value.changed_value,
            });
        } else {
            // alert('File Upload weirdness' + String(value));
        }
    }

    change_value() {
        this.props.change_value(this.props.key_value, this.state.current_value);
        this.setState({ edit: false });
    }

    render() {
        const button_style = {
            cursor: 'pointer',
            padding: '5px 15px',
            height: '34px',
            minWidth: 'auto',
        };
        const icon_style = button_style;
        icon_style.float = 'right';

        let display = (
            <span
                onClick={() => this.setState({ edit: true })}
                style={{ cursor: 'pointer' }}
            >
                <div className="btn btn-primary" style={button_style}>
                    <Icon size={1} icon="edit" style={{ color: 'white' }} />
                </div>
                <span style={{ overflowWrap: 'break-word' }}>
                    {this.props.value}
                </span>
            </span>
        );

        if (!this.props.value) {
            display = (
                <span
                    className="btn btn-primary"
                    onClick={() => this.setState({ edit: true })}
                    style={button_style}
                >
                    Add Value
                </span>
            );
        }

        if (this.state.edit) {
            let input = (
                <TextInput
                    name="changed_value"
                    value={this.state.current_value}
                    handlechange={e =>
                        this.setState({ current_value: e.target.value })
                    }
                    placeholder="Change Value Here"
                />
            );

            if (this.props.value_type == 'audio') {
                input = (
                    <AudioInput
                        setFormState={this.set_state_from_image}
                        name="changed_value"
                        value={this.state.current_value}
                        handlechange={e =>
                            this.setState({ current_value: e.target.value })
                        }
                        placeholder="Change Value Here"
                    />
                );
            } else if (this.props.value_type == 'image') {
                input = (
                    <ImageInput
                        name="changed_value"
                        value={this.state.current_value}
                        setFormState={this.set_state_from_image}
                        placeholder="Change Value Here"
                        preview
                        preview_width="50%"
                    />
                );
            } else if (this.props.value_type == 'file') {
                input = (
                    <FileInput
                        name="changed_value"
                        value={this.state.current_value}
                        setFormState={this.set_state_from_image}
                        placeholder="Change Value Here"
                        preview
                        preview_width="50%"
                    />
                );
            }

            display = (
                <div style={{ padding: '10px 0px' }}>
                    {input}
                    <div
                        className="btn btn-primary"
                        onClick={this.change_value}
                    >
                        Set Value
                    </div>
                </div>
            );
        }

        return display;
    }
}

class DictLayer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            new_key: null,
            error: '',
        };

        this.add_key = this.add_key.bind(this);
        this.change_key = this.change_key.bind(this);
        this.change_value = this.change_value.bind(this);
        this.handle_change = this.handle_change.bind(this);
        this.delete_key = this.delete_key.bind(this);
    }

    handle_change(key, new_state) {
        const form_state = JSON.parse(JSON.stringify(this.props.value));
        form_state[key] = new_state;

        this.props.handle_change(this.props.key_value, form_state);
    }

    change_value(key, new_value) {
        const value = JSON.parse(JSON.stringify(this.props.value));
        value[key] = new_value;

        this.props.handle_change(this.props.key_value, value);

        this.setState({
            error: '',
        });
    }

    change_key(old_key, new_key) {
        if (old_key == new_key) {
            return null;
        }

        const value = JSON.parse(JSON.stringify(this.props.value));
        value[new_key] = value[old_key];
        delete value[old_key];

        this.props.handle_change(this.props.key_value, value);

        this.setState({
            error: '',
        });
    }

    delete_key(old_key) {
        const value = JSON.parse(JSON.stringify(this.props.value));
        delete value[old_key];

        this.props.handle_change(this.props.key_value, value);

        this.setState({
            error: '',
        });
    }

    add_key(state) {
        const form_state = JSON.parse(JSON.stringify(this.props.value));
        const { new_key } = this.state;
        if (!new_key) {
            this.setState({
                error: 'You cannot add a blank key',
            });

            return null;
        }

        form_state[new_key] = null;

        this.props.handle_change(this.props.key_value, form_state);

        this.setState({
            error: '',
        });
    }

    render() {
        const { value } = this.props;
        const keys = [];

        let keys_to_display = LANGUAGES;
        if (this.props.custom_keys) {
            keys_to_display = Object.keys(value);
        }

        keys_to_display.forEach(key => {
            if (Object.keys(value).indexOf(key) == -1) {
                value[key] = null;
            }

            let key_jsx = <span>{`${key}: `}</span>;
            if (this.props.custom_keys) {
                key_jsx = (
                    <EditableKey
                        key={key + '_key'}
                        value={key}
                        change_key={this.change_key}
                        delete_key={this.delete_key}
                    />
                );
            }

            if (!value[key]) {
                keys.push(
                    <div key={key} style={{ marginBottom: '20px' }}>
                        {key_jsx}
                        <EditableValue
                            key={`${key}_value`}
                            key_value={key}
                            value={null}
                            change_value={this.change_value}
                            value_type={this.props.value_type}
                        />
                    </div>
                );
            } else if (is_dict(value[key])) {
                const key_scope = `${this.props.key_scope}_${key}`;

                keys.push(
                    <div key={key} style={{ marginBottom: '20px' }}>
                        {key_jsx}
                        <DictLayer
                            key={key_scope}
                            key_scope={key_scope}
                            key_value={key}
                            value={value[key]}
                            handle_change={this.handle_change}
                            indent={this.props.indent + 1}
                            custom_keys={this.props.custom_keys}
                        />
                    </div>
                );
            } else {
                keys.push(
                    <div key={key} style={{ marginBottom: '20px' }}>
                        {key_jsx}
                        <EditableValue
                            key={`${key}_value`}
                            key_value={key}
                            value={value[key]}
                            change_value={this.change_value}
                            value_type={this.props.value_type}
                        />
                    </div>
                );
            }
        });

        const margin = this.props.indent * 20;

        let add_key = null;
        if (this.props.custom_keys) {
            add_key = (
                <div>
                    <PageBreak
                        style={{ paddingBottom: '10px', marginBottom: '10px' }}
                    />

                    <div style={{ padding: '10px 0px' }}>
                        <TextInput
                            name="new_key"
                            value={this.state.new_key}
                            handlechange={e =>
                                this.setState({ new_key: e.target.value })
                            }
                            placeholder="New Key"
                        />
                        <div className="btn btn-primary" onClick={this.add_key}>
                            Add Key
                        </div>
                    </div>
                </div>
            );
        }

        let error = '';
        if (this.state.error) {
            error = <Alert type="danger">{this.state.error}</Alert>;
        }

        return (
            <div style={{ marginLeft: `${margin}px` }}>
                {keys}
                {add_key}
                {error}
            </div>
        );
    }
}

class DictBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            built_dict: {},
        };

        this.handle_change = this.handle_change.bind(this);
        this.add_row = this.add_row.bind(this);
    }

    componentDidMount() {
        this.check_value_prop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value != prevProps.value) {
            this.check_value_prop();
        }
    }

    handle_change(key, new_state) {
        const new_form_state = {};
        if (this.props.top_level_list) {
            const state = this.state.built_dict;

            if (new_state == null) {
                delete state[key];
            } else {
                state[key] = new_state;
            }

            new_form_state[this.props.name] = state;

            this.setState(
                {
                    built_dict: state,
                },
                this.props.setFormState(new_form_state)
            );
        } else {
            new_form_state[this.props.name] = new_state;

            this.setState(
                {
                    built_dict: new_state,
                },
                this.props.setFormState(new_form_state)
            );
        }
    }

    check_value_prop() {
        let state = {};
        if (!this.props.value) {
            return null;
        }
        if (is_dict(this.props.value)) {
            state = JSON.parse(JSON.stringify(this.props.value));
            if (Object.keys(state).length == 0 && !this.props.custom_keys) {
                state = {};
                LANGUAGES.forEach(item => {
                    state[item] = '';
                });
            }
        } else if (this.props.top_level_list && is_list(this.props.value)) {
            state = JSON.parse(JSON.stringify(this.props.value));
        } else {
            alert(
                'INVALID VALUE IN DICT BUILDER',
                JSON.stringify(this.props.value)
            );
            return null;
        }

        this.setState({
            built_dict: state,
            loaded: true,
        });
    }

    add_row() {
        const state = this.state.built_dict;
        const options = {};
        LANGUAGES.forEach(item => {
            options[item] = '';
        });

        state.push(options);

        this.setState({
            built_dict: state,
        });
    }

    render() {
        let label = null;
        if (this.props.label && this.props.label != '') {
            label = <label htmlFor="input">{this.props.label}</label>;
        }

        let layout = '';
        if (this.props.className) {
            layout = this.props.className;
        }

        const layout_style = {
            position: 'relative',
            background: '#eee',
            padding: '5px',
            boxShadow: '2px 2px 10px rgb(0 0 0 / 10%) inset',
            marginBottom: '10px',
            ...this.props.layout_style,
        };

        let value = this.props.default;
        if (this.props.value) {
            value = this.props.value;
        }

        let input = null;
        if (this.state.loaded) {
            input = (
                <div
                    className="simple-card"
                    style={{ boxShadow: '2px 2px 10px rgb(0 0 0 / 10%)' }}
                >
                    <DictLayer
                        key="top"
                        key_scope="top"
                        key_value={null}
                        value={this.state.built_dict}
                        handle_change={this.handle_change}
                        value_type={this.props.value_type}
                        indent={0}
                        custom_keys={this.props.custom_keys}
                    />
                </div>
            );

            if (this.props.top_level_list) {
                input = [];
                let index = 0;
                this.state.built_dict.forEach(item => {
                    input.push(
                        <div
                            key={index}
                            className="simple-card"
                            style={{
                                boxShadow: '2px 2px 10px rgb(0 0 0 / 10%)',
                            }}
                        >
                            <DictLayer
                                key={`top_${index}`}
                                key_scope={`top_${index}`}
                                key_value={index}
                                value={item}
                                handle_change={this.handle_change}
                                value_type={this.props.value_type}
                                indent={0}
                                custom_keys={this.props.custom_keys}
                            />
                        </div>
                    );

                    index += 1;
                });

                input.push(
                    <div
                        key="new_row"
                        className="simple-card"
                        style={{ boxShadow: '2px 2px 10px rgb(0 0 0 / 10%)' }}
                    >
                        <div className="btn btn-primary" onClick={this.add_row}>
                            Add Row
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className={layout} style={layout_style}>
                <div
                    className="simple-card"
                    style={{ boxShadow: '2px 2px 10px rgb(0 0 0 / 10%)' }}
                >
                    {label}
                </div>
                {input}
            </div>
        );
    }
}

export default DictBuilder;
