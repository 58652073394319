import React, { Component } from 'react';
import resolveVariables from 'base/resolver.js';
import { Card } from 'library';
import ajaxWrapperFile from 'base/ajaxFile.js';
import ajaxWrapper from 'base/ajax.js';

import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from 'react-sortable-hoc';

const SortableItem = SortableElement(({ value, removeImage }) => (
    <Card
        css={{ display: 'inline-block', width: '20%' }}
        imageUrl={value.url}
        id={value.id}
        button="Remove Picture"
        button_type="danger"
        onClick={removeImage}
    />
));

const SortableList = SortableContainer(({ items, removeImage }) => (
    <ul>
        {items.map((value, index) => (
            <SortableItem
                key={`item-${index}`}
                index={index}
                value={value}
                removeImage={removeImage}
            />
        ))}
    </ul>
));

// var main_image = {'value':'', 'name':'main_image', 'label':'Display Image', 'multiple':false}
// <PhotoInput {...main_image} />

class PhotoInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: null,
            uploaded_files: [],
        };

        this.onChange = this.onChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.fileUploadCallback = this.fileUploadCallback.bind(this);
        this.removeImage = this.removeImage.bind(this);
    }

    onChange(e) {
        this.setState(
            { files: e.target.files, uploaded_files: this.props.value },
            this.fileUpload(e.target.files)
        );
    }

    fileUpload(files) {
        const url = '/api/photoUpload/';
        const formData = new FormData();
        Object.keys(files).forEach(index => {
            if (index != 'length' && index != 'item') {
                formData.append('files[]', files[index]);
            }
        });
        formData.append('name', this.props.name);
        ajaxWrapperFile('POST', url, formData, this.fileUploadCallback);
    }

    removeImage(imageProps) {
        if (this.props.multiple == true) {
            const url = imageProps.imageUrl;
            const uploaded_files = this.props.value;
            const order_files = [];
            Object.keys(uploaded_files).forEach(index => {
                const file = uploaded_files[index];
                if (file.url != url) {
                    order_files.push({
                        url: file.url,
                        order: index,
                        filename: file.filename,
                    });
                }
            });

            if (this.props.deleteUrl) {
                const { deleteUrl } = resolveVariables(
                    { deleteUrl: this.props.deleteUrl },
                    imageProps
                );
                ajaxWrapper('POST', deleteUrl, {}, console.log);
            }
            const formState = {};
            formState[this.props.name] = order_files;

            this.props.setFormState(formState);
        } else {
            const formState = {};
            formState[this.props.name] = '';

            this.props.setFormState(formState);
        }
    }

    fileUploadCallback(value) {
        let uploaded_files = this.props.value;

        if (this.props.multiple == true) {
            if (uploaded_files == undefined) {
                uploaded_files = [];
            }
            Object.keys(value.uploaded_files).forEach(index => {
                uploaded_files.push(value.uploaded_files[index]);
            });

            uploaded_files.sort(
                (a, b) => parseInt(a.order, 10) - parseInt(b.order, 10)
            );
        } else {
            if (uploaded_files == undefined) {
                uploaded_files = '';
            }

            uploaded_files = value.uploaded_files[0].url;
        }

        const formFiles = {};

        formFiles[this.props.name] = uploaded_files;
        this.setState({ uploaded_files }, this.props.setFormState(formFiles));
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        if (this.props.multiple == true) {
            const uploaded_files = arrayMove(
                this.props.value,
                oldIndex,
                newIndex
            );
            const order_files = [];
            Object.keys(uploaded_files).forEach(index => {
                const file = uploaded_files[index];
                if (file.order != index) {
                    file.order = index;
                }
                order_files.push({
                    url: file.url,
                    order: index,
                    filename: file.filename,
                });
            });
            const formState = {};
            formState[this.props.name] = order_files;

            this.props.setFormState(formState);
        }
    };

    render() {
        const input = this.props.multiple ? (
            <input
                onChange={this.onChange}
                type="file"
                className="form-control-file"
                id="exampleFormControlFile1"
                name={this.props.name}
                multiple
            />
        ) : (
            <input
                onChange={this.onChange}
                type="file"
                className="form-control-file"
                id="exampleFormControlFile1"
                name={this.props.name}
            />
        );

        let previewText = 'Preview Your Photo Below';
        if (this.props.multiple == true) {
            previewText = 'Re-order your photos below';
        }

        let pictureViewer = <div />;
        if (this.props.value.length > 0) {
            let value;
            if (this.props.multiple == true) {
                value = this.props.value;
            } else {
                value = [{ filename: '', url: this.props.value, order: 0 }];
            }

            pictureViewer = (
                <div className="jumbotron">
                    <h4>{previewText}</h4>
                    <SortableList
                        axis="x"
                        items={value}
                        onSortEnd={this.onSortEnd}
                        removeImage={this.removeImage}
                    />
                    ;
                </div>
            );
        }

        return (
            <div className="form-group">
                <label htmlFor="exampleFormControlFile1">
                    {this.props.label}
                </label>
                {input}
                {pictureViewer}
            </div>
        );
    }
}

export default PhotoInput;
