import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Form,
    TextInput,
    Select,
    Header,
    FormWithChildren,
    FileInput,
    Image,
    Wrapper,
} from 'library';

class EditPDFForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            name: '',
            url: '',
            fields_to_get: [],
            fields: [],
        };

        this.objectCallback = this.objectCallback.bind(this);
        this.objectFailback = this.objectFailback.bind(this);
        this.reload = this.reload.bind(this);
        this.save_form = this.save_form.bind(this);
        this.convert_to_images = this.convert_to_images.bind(this);
        this.save_field_names = this.save_field_names.bind(this);
        this.get_type_options = this.get_type_options.bind(this);
    }

    componentDidMount() {
        if (this.props.form_id) {
            this.reload();
        } else {
            this.setState({ loaded: true });
        }

        if (this.state.fields_to_get.length > 0) {
            ajaxWrapper(
                'GET',
                `/api/pdf_printer/${  this.state.fields_to_get[0]  }/fields/`,
                {},
                this.save_field_names
            );
        }

        if (this.props.type) {
            ajaxWrapper(
                'GET',
                `/api/pdf_printer/${  this.props.type  }/`,
                {},
                this.get_type_options
            );
        }
    }

    objectCallback(result) {
        console.log('Result', result);
        const state = result[0].pdfform;
        state.loaded = true;
        this.setState(state);
    }

    objectFailback(xhr) {
        window.location.href = '/pdf/formlist/';
    }

    get_type_options(value) {
        const options = [];
        for (const i in value) {
            const item = value[i][this.props.type];
            const data = {
                text: item.name,
                value: item.id,
            };
            options.push(data);
        }

        this.setState({
            options,
        });
    }

    save_field_names(value) {
        const name = this.state.fields_to_get[0];
        const {fields} = this.state;
        for (const i in value) {
            if (
                ['id', 'created_at', 'updated_at'].indexOf(value[i][0]) == -1 &&
                value[i][1] != 'ForeignKey'
            ) {
                const field_name = `${name  }.${  value[i][0]}`;
                fields.push(field_name);
            }
        }
        this.state.fields_to_get.shift();

        this.setState(
            {
                fields,
                fields_to_get: this.state.fields_to_get,
            },
            function () {
                if (this.state.fields_to_get.length > 0) {
                    ajaxWrapper(
                        'GET',
                        `/api/pdf_printer/${ 
                            this.state.fields_to_get[0] 
                            }/fields/`,
                        {},
                        this.save_field_names
                    );
                }
            }
        );
    }

    save_form(state) {
        state[0].pdfform.saving = true;

        if (this.props.form_id) {
            this.setState(state[0].pdfform, this.reload);
        } else {
            this.setState(state[0].pdfform, this.convert_to_images);
        }
    }

    convert_to_images() {
        let {form_id} = this.props;
        if (!form_id) {
            form_id = this.state.id;
        }

        ajaxWrapper(
            'POST',
            `/pdf/convertPDFToJPG/${  form_id  }/`,
            {},
            this.reload
        );
    }

    reload() {
        if (this.props.form_id) {
            ajaxWrapper(
                'GET',
                `/api/pdf_printer/pdfform/${ 
                    this.props.form_id 
                    }/?related=mapping,pages`,
                {},
                this.objectCallback,
                this.objectFailback
            );
        } else {
            window.location.href = `/pdf/editform/${  this.state.id  }/`;
        }

        this.setState({ saving: false });
    }

    render() {
        const Components = [TextInput, Select, FileInput];
        const name = {
            name: 'name',
            label: 'Form Name',
            placeholder: 'Name',
        };
        const select = {
            name: this.props.type,
            options: this.state.options,
        };
        if (this.props.type == 'company') {
            select.label = 'Company';
        } else if (this.props.type == 'provider') {
            select.label = 'Provider';
        }
        const form_url = {
            // bucket_name: 'companyforms',
            name: 'url',
            label: 'PDF Form',
        };

        const ComponentProps = [name, select, form_url];

        const defaults = {
            name: this.state.name,
            url: this.state.url,
        };
        defaults[this.props.type] = this.state[`${this.props.type  }_id`];

        let submitUrl = '/api/pdf_printer/pdfform/';
        let deleteUrl = null;
        let deleteRedirectUrl = null;
        const redirect = this.save_form;

        if (this.props.form_id) {
            Components.pop();
            ComponentProps.pop();
            submitUrl += `${this.props.form_id  }/`;
            deleteUrl =
                `/api/pdf_printer/pdfform/${  this.props.form_id  }/delete/`;
            deleteRedirectUrl = '/pdf/formlist/';
        }

        let title = <Header text="Create New Form" size={2} />;
        if (this.props.form_id) {
            title = <Header text={`Edit Form: ${  this.state.name}`} size={2} />;
        }

        const image_editors = [];
        for (const i in this.state.pages) {
            image_editors.push(
                <ImageEditor
                    url={this.state.url}
                    fields={this.state.fields}
                    reload={this.reload}
                    mapping={this.state.mapping}
                    page={this.state.pages[i].pdfpage}
                    form_id={this.props.form_id}
                />
            );
        }

        const content = (
            <div className="container" style={{ position: 'relative' }}>
                {title}
                <Form
                    components={Components}
                    componentProps={ComponentProps}
                    objectName="pdfform"
                    defaults={defaults}
                    redirect={redirect}
                    deleteUrl={deleteUrl}
                    submitUrl={submitUrl}
                    deleteRedirectUrl={deleteRedirectUrl}
                />
                <br />

                {image_editors}
            </div>
        );

        return (
            <div>
                <Wrapper
                    saving={this.state.saving}
                    loaded={this.state.loaded}
                    content={content}
                />
            </div>
        );
    }
}

class ImageEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            start_position: null,
            middle_position: null,
            end_position: null,
            page_offset: { x: 0, y: 0 },
        };

        this.get_percentage_from_event =
            this.get_percentage_from_event.bind(this);
        this.make_start_position = this.make_start_position.bind(this);
        this.make_end_position = this.make_end_position.bind(this);
        this.move_position = this.move_position.bind(this);

        this.image_loaded = this.image_loaded.bind(this);
        this.save_mapping = this.save_mapping.bind(this);
        this.position_ref = React.createRef();
    }

    image_loaded() {
        this.setState({
            page_offset: {
                x: this.position_ref.current.offsetWidth,
                y: this.position_ref.current.offsetHeight,
            },
        });
    }

    get_percentage_from_event(e) {
        const x_percent =
            (e.clientX -
                this.position_ref.current.parentNode.offsetLeft -
                this.position_ref.current.offsetLeft +
                window.scrollX) /
            this.state.page_offset.x;
        const y_percent =
            (e.clientY -
                this.position_ref.current.offsetTop +
                window.scrollY -
                110) /
            this.state.page_offset.y;

        return [x_percent, y_percent];
    }

    make_start_position(e) {
        e.preventDefault();

        const position = this.get_percentage_from_event(e);

        this.setState({
            start_position: {
                x: position[0].toFixed(4),
                y: position[1].toFixed(4),
            },
            middle_position: null,
            end_position: null,
        });
    }

    make_end_position(e) {
        const position = this.get_percentage_from_event(e);

        this.setState({
            end_position: {
                x: position[0].toFixed(4),
                y: position[1].toFixed(4),
            },
        });
    }

    move_position(e) {
        e.preventDefault();
        if (!this.state.start_position || this.state.end_position) {
            return false;
        }

        const position = this.get_percentage_from_event(e);

        this.setState({
            middle_position: {
                x: position[0].toFixed(4),
                y: position[1].toFixed(4),
            },
        });
    }

    save_mapping() {
        this.setState({
            start_position: null,
            middle_position: null,
            end_position: null,
        });
        this.props.reload();
    }

    render() {
        var width = window.innerWidth;
        var height = window.innerHeight;
        const image_style = { width: '100%' };

        let bounding_box = null;
        if (this.state.start_position && this.state.middle_position) {
            var left = this.state.start_position.x * this.state.page_offset.x;
            var top = this.state.start_position.y * this.state.page_offset.y;

            var width =
                this.state.middle_position.x * this.state.page_offset.x - left;
            var height =
                this.state.middle_position.y * this.state.page_offset.y - top;

            const box_style = {
                top: `${String(top)  }px`,
                left: `${String(left)  }px`,
                width: `${String(width)  }px`,
                height: `${String(height)  }px`,
            };
            bounding_box = (
                <div className="pdf-map-boundingbox" style={box_style} />
            );
        }

        let mapper = null;
        if (this.state.end_position) {
            const option_style = {
                position: 'absolute',
                padding: '10px',
                boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
            };
            option_style.left =
                this.state.end_position.x * this.state.page_offset.x;
            option_style.top =
                this.state.end_position.y * this.state.page_offset.y;

            const defaults = {
                form: this.props.form_id,
                page: this.props.page.id,
                start_x: this.state.start_position.x,
                start_y: this.state.start_position.y,
                end_x: this.state.end_position.x,
                end_y: this.state.end_position.y,
            };

            var redirect = this.save_mapping;

            mapper = (
                <div className="card" style={option_style}>
                    <div>
                        {`{'start': [${ 
                            this.state.start_position.x 
                            }, ${ 
                            this.state.start_position.y 
                            }], 'end': [${ 
                            this.state.end_position.x 
                            }, ${ 
                            this.state.end_position.y 
                            }]}`}
                    </div>
                    <FormWithChildren
                        submitUrl="/api/pdf_printer/pdfformmapping/"
                        objectName="pdfformmapping"
                        defaults={defaults}
                        redirect={redirect}
                    >
                        <TextInput name="name" label="Name" />
                    </FormWithChildren>
                </div>
            );
        }

        const mappings = [];
        for (const i in this.props.mapping) {
            const mapping = this.props.mapping[i].pdfformmapping;
            if (mapping.page_id == this.props.page.id) {
                const deleteUrl =
                    `/api/pdf_printer/pdfformmapping/${ 
                    mapping.id 
                    }/delete/`;
                var redirect = this.save_mapping;

                const mapping_style = {
                    position: 'absolute',
                    padding: '10px',
                    boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
                };
                mapping_style.left =
                    mapping.end_x * this.state.page_offset.x;
                mapping_style.top = mapping.end_y * this.state.page_offset.y;

                mappings.push(
                    <div className="card" style={mapping_style}>
                        <div style={{ marginRight: '75px', padding: '3px' }}>
                            {mapping.name}
                        </div>
                        <FormWithChildren
                            redirect={redirect}
                            objectName="pdfformmapping"
                            deleteUrl={deleteUrl}
                            defaults={{}}
                        />
                    </div>
                );

                var left = mapping.start_x * this.state.page_offset.x;
                var top = mapping.start_y * this.state.page_offset.y;

                var width = mapping.end_x * this.state.page_offset.x - left;
                var height = mapping.end_y * this.state.page_offset.y - top;

                const mapping_box_style = {
                    top: `${String(top)  }px`,
                    left: `${String(left)  }px`,
                    width: `${String(width)  }px`,
                    height: `${String(height)  }px`,
                };
                const mapping_bounding_box = (
                    <div
                        className="pdf-map-boundingbox"
                        style={mapping_box_style}
                     />
                );
                mappings.push(mapping_bounding_box);
            }
        }

        return (
            <div style={{ position: 'relative' }} ref={this.position_ref}>
                {mapper}
                {mappings}

                <div
                    onMouseDown={this.make_start_position}
                    onMouseMove={this.move_position}
                    onMouseUp={this.make_end_position}
                >
                    {bounding_box}
                    <Image
                        src={this.props.page.url}
                        css={image_style}
                        onLoad={this.image_loaded}
                    />
                </div>
            </div>
        );
    }
}

export default EditPDFForm;
