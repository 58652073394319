import React from 'react';

import { Form, PasswordInput, Wrapper } from 'library';

export default function ForcedPasswordReset(props) {
    const Components = [PasswordInput];
    const password_props = { confirm_password: true };

    const ComponentProps = [password_props];
    const defaults = { password: '', forced_password_reset: false };
    const { user_id } = props;
    const submitUrl = `/users/resetPassword/${user_id}/`;

    const content = (
        <div className="container">
            <h2>
                Due To Student Data Being Available, all users are required to
                reset their password. Please type in a new password below.
            </h2>
            <Form
                components={Components}
                redirectUrl="/work/"
                componentProps={ComponentProps}
                submitUrl={submitUrl}
                defaults={defaults}
            />
        </div>
    );

    return <Wrapper loaded content={content} />;
}
